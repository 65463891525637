import { useGetEndedEventQuery } from "services/event-service";
import {
  CalenderIcon,
  GameConsole,
  LocationIcon,
  Clock,
  ForwardIcon,
} from "presentation/assets";
import { Button, Card, Col, Row, Image, Spinner } from "react-bootstrap";
import { Event, Search } from "models/request/create-event.request";
import { Link } from "react-router-dom";
import { CiVideoOn } from "react-icons/ci";
import OwlCarouselComponent from "../components/OwlCarouselComponent";

const EndedEventCard: React.FC<Search> = ({ searchQuery }) => {
  const { data: response, isLoading } = useGetEndedEventQuery({
    page: 0,
    size: 99999999,
    title: searchQuery,
  });

  const events: Event[] = (response?.data[0]?.data ?? []) as Event[];
  const filteredEvents = events.filter((event) =>
    event.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (isLoading) return <Spinner />;

  if (events && events?.length === 0) {
    return <p style={{ marginLeft: "1.5rem" }}>No ended events available</p>;
  }

  return (
    <Col className="p-4">
      <h5 className="py-3">Ended</h5>
      <OwlCarouselComponent>
        {filteredEvents.map((event) => (
          <Card
            className="bg-white rounded-4"
            key={event.id}
            style={{ height: "250px", maxWidth: "468px", objectFit: "cover" }}
          >
            <Row className="g-0">
              {event.image && (
                <Col className=" rounded-start-4">
                  <Image
                    alt="Event Image"
                    src={event.image}
                    className="img-fluid  rounded-start-4"
                    style={{
                      minHeight: "100%",
                      width: "50%",
                      objectFit: "cover",
                    }}
                  />
                </Col>
              )}
              <Col>
                <Card.Body style={{ maxWidth: "260px" }}>
                  <div className="d-flex justify-content-between">
                    <Link
                      className="text-decoration-none text-black"
                      to={`/events/event-details/${event.id}`}
                    >
                      <Card.Title className="event-card-title">
                        {event.title?.length > 22
                          ? `${event.title.substring(0, 22)}...`
                          : event.title}
                      </Card.Title>
                    </Link>
                  </div>

                  <ul className="list-unstyled d-flex flex-column event-card-text my-1">
                    {event.category === "VIRTUAL_SESSION" ? (
                      <li className="icon-link text-decoration-underline">
                        <a
                          href={event.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <CiVideoOn className="fs-6 me-2 " />
                          {event.link?.length > 22
                            ? `${event.link.substring(0, 22)}...`
                            : event.link}
                        </a>
                      </li>
                    ) : (
                      <>
                        {event.category === "MEDIA_ENGAGEMENT" && (
                          <>
                            <li className="icon-link">
                              <Image src={LocationIcon} alt="" width={20} />
                              {event.location?.nameOfVenue}-
                              {event.location?.city}
                            </li>
                          </>
                        )}
                        {(event.category === "IN_PERSON_SESSION_MENTORSHIP" ||
                          event.category === "IN_PERSON_SESSION_COUNSELLING" ||
                          event.category === "IN_PERSON_SESSION") && (
                          <li className="icon-link">
                            <Image src={LocationIcon} alt="" width={20} />
                            {event.location?.nameOfVenue}-{event.location?.city}
                          </li>
                        )}
                      </>
                    )}
                    <li className="icon-link">
                      <Image src={Clock} alt="" width={20} />
                      {event.stime} - {event.etime}
                    </li>

                    <li className="icon-link text-nowrap">
                      <Image src={CalenderIcon} alt="" width={20} />
                      {event.startDay} - {event.endDay}
                    </li>
                  </ul>

                  <div className="d-flex align-items-center">
                    <Image
                      alt=""
                      className="rounded-circle p-1 bg-light"
                      src={GameConsole}
                      width={24}
                    />
                    <hr className="hr w-100"></hr>
                  </div>

                  <Card.Text className="event-card-text text-small text-muted">
                    {event.description?.length > 50
                      ? `${event.description.substring(0, 50)}...`
                      : event.description}
                  </Card.Text>

                  <Link
                    className="text-white"
                    to={`/events/event-details/${event.id}`}
                  >
                    <Button variant="dark event-button fs-6 rounded-5">
                      View details
                      <img
                        className="ms-2 d-inline-flex"
                        src={ForwardIcon}
                        alt=""
                        width="14px"
                      />
                    </Button>
                  </Link>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        ))}
      </OwlCarouselComponent>
    </Col>
  );
};

export default EndedEventCard;
