import React from "react";
import { RiAccountCircleLine } from "react-icons/ri";
import { VscSymbolColor } from "react-icons/vsc";
import {
  IoIosHelpCircleOutline,
  IoIosNotificationsOutline,
} from "react-icons/io";
import { IoLockClosedOutline } from "react-icons/io5";

export class SettingSidebarData {
  static PersonalSettings = [
    {
      name: "Account",
      icon: <RiAccountCircleLine className="fs-4 p-1 text-white bg-black rounded-5" />,
      url: "/account",
    },
    {
      name: "Notifications",
      icon: <IoIosNotificationsOutline className="fs-4 p-1 text-white bg-black rounded-5"/>,
      url: "/notifications",
    },
    {
      name: "Security",
      icon: <IoLockClosedOutline className="fs-4 p-1 text-white bg-black rounded-5"/>,
      url: "/security",
    },
    {
      name: "Appearance",
      icon: <VscSymbolColor className="fs-4 p-1 text-white bg-black rounded-5"/>,
      url: "/appearance",
    },
  ];

  static GeneralSettings = [
    {
      name: "Help",
      icon: <IoIosHelpCircleOutline className="fs-4 p-1 text-white bg-black rounded-5"/>,
      url: "/enquiries/make",
    },
  ];
}
