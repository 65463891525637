import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FiAlignLeft } from "react-icons/fi";
import Sidebar from "./Sidebar";

function MobileSidebar() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <FiAlignLeft className="text-black fs-4" onClick={handleShow} />

      <Offcanvas style={{width: "250px"}} show={show} onHide={handleClose}>
        <Sidebar />
      </Offcanvas>
    </>
  );
}

export default MobileSidebar;
