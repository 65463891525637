import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { CiFilter } from "react-icons/ci";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const FilterModal = ({ onFilter, resetPage }) => {
  const [show, setShow] = useState(false);
  const [ageFilter, setAgeFilter] = useState("");
  const [genderFilter, setGenderFilter] = useState("");
  const [registrationFilter, setRegistrationFilter] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFilter = () => {
    const formattedStartDate = startDate
      ? startDate.toISOString().split("T")[0]
      : null;
    const formattedEndDate = endDate
      ? endDate.toISOString().split("T")[0]
      : null;
    onFilter({
      age: ageFilter,
      gender: genderFilter,
      registration_channel: registrationFilter,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
    });
    resetPage();
    handleClose();
  };

  const handleClear = () => {
    setAgeFilter("");
    setGenderFilter("");
    setRegistrationFilter("");
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <>
      <Button
        variant="light"
        className="rounded-5 "
        style={{ fontSize: "12px", background: "rgba(25, 22, 29, 0.06)" }}
        onClick={handleShow}
      >
        Filter <CiFilter />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Filter Users</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 m-0">
          <Row className="px-5 my-2">
            <Form.Select
              className="event-details-form"
              aria-label="Default select example"
              name="category"
              onChange={(e) => setAgeFilter(e.target.value)}
            >
              <option className="text-muted" value="" selected disabled>
                Filter by age
              </option>
              <option value="AGE_BELOW_15">Below 15yrs</option>
              <option value="AGE_15_17">15 – 17 yrs</option>
              <option value="AGE_18_24">18 – 24 yrs</option>
              <option value="AGE_25_30">25 – 30 yrs </option>
              <option value="AGE_31_35">31 – 35 years </option>
              <option value="AGE_36_PLUS">36+ years </option>
            </Form.Select>
          </Row>
          <Row className="px-5 my-2">
            <Form.Select
              className="event-details-form"
              aria-label="Default select example"
              name="category"
              onChange={(e) => setGenderFilter(e.target.value)}
            >
              <option className="text-muted" value="" selected disabled>
                Filter by gender
              </option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </Form.Select>
          </Row>
          <Row className="px-5 my-2">
            <Form.Select
              className="event-details-form"
              aria-label="Default select example"
              name="category"
              onChange={(e) => setRegistrationFilter(e.target.value)}
            >
              <option className="text-muted" value="" selected disabled>
                Filter by registration channel
              </option>
              <option value="WEB">Web</option>
              <option value="USSD">Ussd</option>
              <option value="BULK_REGISTRATION">Bulk Registration</option>
              <option value="EMPLOYER">Employer</option>
              <option value="ONBOARDED">Onboarded</option>
            </Form.Select>
          </Row>
          <Row className="px-5 my-2">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Col className="px-0">
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </Col>
              <Col className="px-0">
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              </Col>
            </LocalizationProvider>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            className="users-text rounded-5"
            onClick={handleClear}
          >
            Clear
          </Button>
          <Button
            className="users-text rounded-5"
            variant="primary"
            onClick={handleFilter}
          >
            Filter
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FilterModal;
