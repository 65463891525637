import { GridColDef } from "@mui/x-data-grid";
import EditMediaHouseModal from "../components/EditMediaHouseModal";
import DeleteMediaHouseModal from "../components/DeleteMediaHouseModal";

const MediaHouseTable: GridColDef[] = [
  {
    field: "id",
    headerName: "Id",
    flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "name",
    headerName: "Media Name",
    flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "category",
    headerName: "Category",
    flex: 1,
    headerClassName: "header--bg",
  },

  {
    field: "actions",
    headerName: "Actions",
    headerClassName: "header--bg",
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      console.log("params", params);
      return (
        <div>
          <EditMediaHouseModal mediaHouseData={params?.row} />
          <DeleteMediaHouseModal mediaHouseID={params?.row?.id} />
        </div>
      );
    },
  },
];

export default MediaHouseTable;
