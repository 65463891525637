import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import {
  useAssignCounsellorMutation,
  useGetCounsellorListQuery,
} from "services/counselling-service";
import { assignCounsellorRequest } from "models/request/counselling-request";
import { IoIosArrowDown } from "react-icons/io";

const AssignCounsellor = ({ requestId }) => {
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [assignData, setAssignData] = useState<assignCounsellorRequest>({
    requestId: requestId,
    counsellors: [0],
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [assignCounsellor] = useAssignCounsellorMutation();

  const { data: Response, isLoading } = useGetCounsellorListQuery({
    page_index: 0,
    page_size: 99999999,
  });

  const counsellors = Response?.data;

  const counsellOptions = counsellors?.map((counsel) => ({
    value: counsel.id,
    label: `${counsel.user.firstName} ${counsel.user.lastName}`,
  }));

  const handleUserSelectChange = (selectedOption) => {
    setSelectedUser(selectedOption);
    // Assuming 'assignData' is supposed to store the selected counsellors
    setAssignData({
      ...assignData,
      counsellors: selectedOption.map((option) => option.value),
    });
  };

  const handleCreateCounsellor = async (e) => {
    e.preventDefault();
    try {
      const response = await assignCounsellor(assignData);
      const { code, message } = response["data"];
      if (code === 0) {
        toast.success(message);
      } else {
        toast.error(message || "Something went wrong");
      }
      handleClose();
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating counsellor:", error);
    }
  };

  return (
    <>
      <Button
        className="py-2 mx-1 rounded-5 "
        variant="dark"
        style={{ fontSize: "13px" }}
        onClick={handleShow}
      >
        Assign Counsellor <IoIosArrowDown />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Assign Counsellor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "14px" }}>Counsellors</Form.Label>
              <Select
                className="p-2"
                options={counsellOptions}
                onChange={handleUserSelectChange}
                value={selectedUser}
                isLoading={isLoading}
                isDisabled={isLoading}
                isMulti
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded-5"
            variant="secondary"
            onClick={handleClose}
            style={{ fontSize: "14px" }}
          >
            Close
          </Button>
          <Button
            style={{ fontSize: "14px" }}
            className="rounded-5"
            variant="primary"
            onClick={handleCreateCounsellor}
            disabled={!selectedUser}
          >
            Assign
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssignCounsellor;
