import { useGetFormListQuery } from "../../../services/form-service";
import { useState, useEffect, useMemo } from "react";
import { Row, Col, Accordion, Badge, Button, Spinner } from "react-bootstrap";
import { PiThumbsUp } from "react-icons/pi";
import { GetFormByType } from "../../utils/Helpers";
import { Answer, FormAnswerRequest } from "models/response/FormAnswerDTO";
import { useLocation, useNavigate } from "react-router-dom";
import { FormQuestion } from "models/response/form-response";
import PageLoader from "../../components/PageLoader";
import SignUpFileUpload from "../../components/SignUpFileUpload";
import toast from "react-hot-toast";
import { useUploadFileMutation } from "../../../services/file-upload-service";
import { useCreateFormAnswerMutation } from "services/form-answers-service";
import { IoSaveOutline } from "react-icons/io5";
import { useUpdateUserByIDMutation } from "services/user-service";
import { RegistrationStatusUpdate } from "models/request/create-user.request";
import AddEmployees from "./components/AddEmployees";

interface ExampleQuestionData {
  id: number;
  name: string;
  questions: FormQuestion[];
}

const SignUpRegistration2 = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [exampleQuestion, setExampleQuestion] = useState<ExampleQuestionData[]>(
    []
  );
  const [numberOfSaveButtons, setNumberOfSaveButtons] = useState(0);
  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);
  const [savedProgressCount, setSavedProgressCount] = useState(0);
  const { data: formResponseData, isLoading } = useGetFormListQuery();
  const [formAnswers, setFormAnswers] = useState<Answer[]>([]);
  const [savedProgress, setSavedProgress] = useState({});
  const [disabledFields, setDisabledFields] = useState<Array<boolean>>([]);

  const [createUserForm] = useCreateFormAnswerMutation<FormAnswerRequest>();
  // handle data from child
  const navigate = useNavigate();

  const [fileData, setFileData] = useState(null);
  const handleData = (data) => {
    setFileData(data);
  };

  const [uploadFileMutation] = useUploadFileMutation();
  const [updateUser] = useUpdateUserByIDMutation();

  const sendAnswer = async (formId: number) => {
    setIsSaving(true);
    console.log("form id ", formId);
    try {
      // Prepare answers array
      const answersArray = formAnswers.map((answer) => ({
        formQuestionId: answer.formQuestionId,
        answerProvided: answer.answerProvided,
      }));

      // Construct payload
      const payload = {
        formId: formId,
        answeredBy: userId,
        answers: answersArray,
      };

      console.log("payload", payload);

      // Make the POST request
      const signupresponse = await createUserForm(payload);
      const { code, message, data } = signupresponse["data"];
      if (code === 201) {
        toast.success(message);
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error("Error");
    } finally {
      setIsSaving(false); // End saving, set isSaving back to false
    }
  };

  const handleUploadFile = async () => {
    try {
      const formData = new FormData();
      formData.append("file", fileData[0]);
      const response = await uploadFileMutation(formData);
      const { code, message, data } = response["data"];

      if (code === 200) {
        toast.success(message);
        // setUploadedFileData(data);
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error("Error uploading file");
      // setUploadStatus("select");
    }
  };

  useEffect(() => {
    const totalSaveButtons = exampleQuestion.length;
    setNumberOfSaveButtons(totalSaveButtons);
    // Initialize the disabled state for each form
    const initialDisabledState = Array(totalSaveButtons).fill(false);
    setDisabledFields(initialDisabledState);
  }, [exampleQuestion]);

  function SubmitAnswers(e, formId: number, sectionIndex: number) {
    e.preventDefault();
    setIsSaving(true);

    if (fileData) {
      sendAnswer(formId);
      handleUploadFile();
    } else {
      sendAnswer(formId);
    }
    // Update the disabled state for the specific form
    const updatedDisabledFields = [...disabledFields];
    updatedDisabledFields[sectionIndex] = true;
    setDisabledFields(updatedDisabledFields);
    // Update the saved progress count
    setSavedProgressCount(savedProgressCount + 1);
    // Set saved progress for this section
    setSavedProgress({ ...savedProgress, [sectionIndex]: true });
    setIsSaving(false); // End saving, set isSaving back to false
  }

  const onQuestionAnswered = (
    answer: any,
    question: FormQuestion,
    isDropDown: Boolean | false
  ) => {
    if (isDropDown) {
      answer = JSON.parse(answer).value;
    }

    const updatedAnswers = formAnswers.map((ans) =>
      ans.formQuestionId === question.id
        ? { ...ans, answerProvided: answer }
        : ans
    );
    const answerExists = updatedAnswers.some(
      (ans) => ans.formQuestionId === question.id
    );
    setFormAnswers(
      answerExists
        ? updatedAnswers
        : [
            ...updatedAnswers,
            { answerProvided: answer, formQuestionId: question.id },
          ]
    );
  };

  const location = useLocation();
  const registeredData = useMemo(() => location.state, [location.state]);

  // render file input field based on category
  const renderFileInput = (formName) => {
    const fileSupportedCategories = [
      "Support Partner(Volunteer)",
      "Support Partner(Vendor)",
      "Support Partner(Facilitator)",
    ];
    const condition = fileSupportedCategories?.includes(formName);

    if (condition) {
      return <SignUpFileUpload getData={handleData} />;
    } else {
      return null;
    }
  };

  const formFields = useMemo(
    () => (formResponseData?.data ? formResponseData.data : undefined),
    [formResponseData]
  );

  useEffect(() => {
    // console.log("fileUploadData response::>>",fileUploadData);
    if (!isLoading && formResponseData) {
      const newValue = [];

      console.log("registeredData :>>", registeredData);

      registeredData?.springboardCategory?.forEach((item) => {
        formFields.forEach((formQuestion) => {
          const { id, title, questions } = formQuestion;
          const selectedCategories = title.split(",");
          const containsAny = selectedCategories.some((option) =>
            item.includes(option)
          );

          console.log("contains any: ", containsAny);
          console.log("selectedCategories: ", selectedCategories);

          if (containsAny) {
            const value: ExampleQuestionData = { id, name: title, questions };
            newValue.push(value);
          }
        });
      });

      setExampleQuestion(newValue);
    }
  }, [
    isLoading,
    formResponseData,
    registeredData?.springboardCategory,
    formFields,
    registeredData,
  ]);

  const getParentAnswer = (formQuestion: FormQuestion) => {
    return formAnswers.find(
      (currentAnswer) =>
        currentAnswer.formQuestionId === formQuestion.parentQuestionId
    );
  };

  if (!formFields) return <PageLoader />;

  console.log("questions :", exampleQuestion);

  const updateRegistrationStatus = async () => {
    try {
      const registrationUpdateData: RegistrationStatusUpdate = {
        id: userId,
        registrationStatus: "COMPLETE",
      };

      const response = await updateUser(registrationUpdateData);
      const { code, message } = response["data"];
      if (code === 200) {
        toast.success("Registration Completed");
        setTimeout(function () {
          navigate("/dashboard");
        }, 2000);
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error:", error);
    }
  };

  return (
    <div className="form-bg p-3 rounded-start-4 px-lg-5">
      <h1 className="fs-4 py-2">Help us know you better</h1>
      <h1 className="fs-6 py-1 text-muted">Kindly enter the details below</h1>

      <Row className="gx-1 my-1 overflow-auto overflow-x-hidden">
        <div>
          {exampleQuestion?.map((form, key) => (
            <form key={key} onSubmit={(e) => SubmitAnswers(e, form.id, key)}>
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item
                  eventKey="0"
                  className="custom-accordion my-5"
                  key={key}
                >
                  <Accordion.Header className="p-0 m-0 d-flex ">
                    <div className="fw-normal text-with-line"></div>
                    <div className="position-absolute top-0 start-0 bg-white ">
                      {form.name}
                    </div>
                    <div className="position-absolute top-0 end-0">
                      <Badge className="text-end mx-5">Success</Badge>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="px-0">
                    <Row className="gx-1 gy-3">
                      {form.questions?.map((formQuestion, questionKey) => {
                        const parentAnswer = getParentAnswer(formQuestion);
                        return (
                          <GetFormByType
                            key={questionKey}
                            question={formQuestion}
                            onSelectQuestion={onQuestionAnswered}
                            bindValue={parentAnswer?.answerProvided}
                            disabled={disabledFields[key]}
                          />
                        );
                      })}
                    </Row>
                    {(registeredData?.springboardCategory === "Employer" ||
                      form.name ===
                        "Employer, Self-Employed, Or Entrepreneur") && (
                      <Row>
                        <AddEmployees />
                      </Row>
                    )}
                    {/* render the selected springboard category that requires file upload */}
                    <Row className="mt-5">{renderFileInput(form?.name)}</Row>
                    <Row className="justify-content-end">
                      <Col md={4} className="text-end">
                        <Button
                          variant="dark"
                          className="rounded-5"
                          type="submit"
                          style={{
                            fontSize: "14px",
                          }}
                          disabled={savedProgress[key]}
                        >
                          {isSaving ? (
                            <>
                              <Spinner size="sm" />
                              &nbsp; Saving...
                            </>
                          ) : (
                            <>
                              Save <IoSaveOutline />
                            </>
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </form>
          ))}
        </div>
        <Row className="mt-5 justify-content-end">
          <Col className="text-end">
            <div className="">
              <Button
                variant="dark"
                className="rounded-5 w-50"
                style={{ fontSize: "14px", fontWeight: "400" }}
                disabled={savedProgressCount !== numberOfSaveButtons}
                onClick={updateRegistrationStatus}
              >
                I’m done
                <PiThumbsUp className="mx-2" />
              </Button>
            </div>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default SignUpRegistration2;
