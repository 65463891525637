import React from "react";

type TextWithLineProps = {
  label: string;
  extraClasses?: string
};
const TextWithLine: React.FC<TextWithLineProps> = ({ label, extraClasses }) => {
  return (
    <div className={`d-flex align-items-center ${extraClasses}`}>
      <h5 className="fw-bold me-2"> {label} </h5>
      <div className="flex-grow-1"> <hr /></div>
    </div>
  );
};

export default TextWithLine;
