import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Avatar from "react-avatar";
import { useAllMentorshipGroupsQuery } from "services/counselling-service";
import { GoArrowRight } from "react-icons/go";
import SharedPagination from "presentation/components/Pagination";
import { useNavigate } from "react-router-dom";
import { registerGroupType } from "models/request/counselling-request";
import EditGroup from "./EditGroup";
import LeaveGroup from "./leaveGroup/LeaveGroup";
import PageLoader from "presentation/components/PageLoader";
import AssignMentorToGroup from "./AssignMentorToGroup";
import ViewMembers from "./ViewMembers";

const AllMentorshipGroups = () => {
  const navigate = useNavigate();

  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const [phoneNumber] = useState(sessionStorage.getItem("phoneNumber"));
  const [firstName] = useState(sessionStorage.getItem("firstName"));
  const [lastName] = useState(sessionStorage.getItem("lastName"));
  const userId = parseInt(storedUserId);

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [pageSize] = useState(6);
  const { data: response, isLoading } = useAllMentorshipGroupsQuery({
    page_index: currentPageIndex,
    page_size: pageSize,
  });

  const mentorGroup = response?.data;
  const totalPages = response?.totalPages;
  const totalElements = response?.totalElements;

  const [groupData, setGroupData] = useState<registerGroupType>({
    requestedBy: [
      {
        userId: userId,
        phone: phoneNumber,
        firstName: firstName,
        lastName: lastName,
      },
    ],
    groupId: 0,
  });

  const roles = sessionStorage.getItem("roles");
  const allowedRoles = ["Admin", "Super Admin", "Call Center"];
  const hasAllowedRole =
    roles && allowedRoles.some((role) => roles.includes(role));

  if (isLoading) return <PageLoader />;

  return (
    <Row className="my-4">
      <>
        {mentorGroup?.map((group) => (
          <Col key={group.id} className="p-3 m-0" md={4}>
            <Card
              className="border-0 p-2 rounded-3"
              style={{ backgroundColor: "#FAFAFA" }}
            >
              <Card.Body>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {group.members.length > 0 ? (
                    group.members.map((member, index) => (
                      <Avatar
                        key={index}
                        name={`${member.firstName} ${member.lastName}`}
                        size="40"
                        round
                        style={{ marginLeft: index !== 0 ? "-15px" : 0 }}
                      />
                    ))
                  ) : (
                    <p>No members</p>
                  )}
                  {group.members.length > 5 && (
                    <div style={{ marginLeft: "-15px" }}>
                      <div
                        className="rounded-circle bg-white d-flex align-items-center justify-content-center"
                        style={{
                          width: "40px",
                          height: "40px",
                          fontSize: "14px",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        +{group.members.length - 5}
                      </div>
                    </div>
                  )}
                </div>
                <Row className="mt-5">
                  <Col>
                    <Card.Text
                      className="fw-bold mb-0"
                      style={{ fontSize: "16px" }}
                    >
                      {group.name}
                    </Card.Text>
                    <Card.Text
                      className="text-muted mb-0"
                      style={{ fontSize: "14px" }}
                    >
                      {group.members.length} members
                    </Card.Text>
                  </Col>
                  {!hasAllowedRole && (
                    <Col>
                      {group.members.some(
                        (member) => member.userId === userId
                      ) ? (
                        <LeaveGroup groupId={group.id} />
                      ) : (
                        <>
                          <Button
                            className="py-2 mx-1 rounded-5 "
                            variant="dark"
                            style={{ fontSize: "13px" }}
                            onClick={() =>
                              navigate("/e-mentoring/request-to-join", {
                                state: {
                                  groupData: {
                                    ...groupData,
                                    groupId: group.id, // Set groupId to the current group's id
                                  },
                                },
                              })
                            }
                          >
                            Request to join <GoArrowRight />
                          </Button>
                        </>
                      )}
                    </Col>
                  )}
                  {hasAllowedRole && (
                    <>
                      <Col>
                        <EditGroup
                          groupId={group.id}
                          groupName={group.name}
                          groupDescription={group.description}
                          groupEnabled={group.enabled}
                        />
                      </Col>
                      <AssignMentorToGroup groupId={group.id} />
                      <ViewMembers groupMembers={group.members} groupId={group.id} />
                    </>
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </>
      <SharedPagination
        currentPage={currentPageIndex + 1}
        onPageChange={(newPage) => setCurrentPageIndex(newPage - 1)}
        totalPages={totalPages}
        pageSize={pageSize}
        totalElements={totalElements}
      />
    </Row>
  );
};

export default AllMentorshipGroups;
