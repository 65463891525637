import {
  useGetEventRegionListQuery,
  useUpdateDistrictMutation,
} from "services/event-service";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { editDistrictType } from "models/request/create-event.request";
import toast from "react-hot-toast";

const EditDistrictModal = ({ distData }) => {
  const [show, setShow] = useState(false);

  const [editDistrictData, setEditDistrictData] = useState<editDistrictType>({
    id: distData.id,
    regionId: distData.region.id,
    name: distData.name,
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setEditDistrictData((prevRegionData) => ({
      ...prevRegionData,
      [name]: value,
    }));
  };

  const handleFormSelectChanged: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    const { name, value } = e.target;
    setEditDistrictData((prevEventData) => ({
      ...prevEventData,
      [name]: value,
    }));
  };

  const { data: response, isLoading } = useGetEventRegionListQuery({
    page: 0,
    size: 99999999,
  });

  const regionResponse = response?.data[0]?.data;

  const RegionOptions: { value: number; label: string }[] = regionResponse?.map(
    (region) => ({
      value: region.id,
      label: region.name,
    })
  );

  // Apollo hook for mutation
  const [updateDistrictMutation] = useUpdateDistrictMutation();

  // Function to handle form submission
  const handleUpdateDistrict = async (e) => {
    e.preventDefault();
    try {
      // Make the mutation call
      const response = await updateDistrictMutation(editDistrictData);
      const { code, message } = response["data"];
      if (code === "00") {
        toast.success(message);
      } else {
        toast.error(message || "Something went wrong");
      }
      // Close the modal
      handleClose();
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating region:", error);
    }
  };

  useEffect(() => {
    setEditDistrictData((prev) => ({
      ...prev,
      id: distData.id,
      regionId: distData.region.id,
      name: distData.name,
    }));
  }, []);

  return (
    <>
      <Button
        className="py-2 rounded-5 mx-auto"
        variant="light"
        style={{ fontSize: "12px", background: "rgba(25, 22, 29, 0.06)" }}
        onClick={handleShow}
      >
        Edit region
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Edit district</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label style={{ fontSize: "14px" }}>Regions</Form.Label>
            <Form.Select
              className="p-2"
              aria-label="Default select example"
              name="regionId"
              onChange={handleFormSelectChanged}
              value={editDistrictData.regionId}
            >
              <option className="text-muted" value="" selected disabled>
                Select region
              </option>
              {RegionOptions?.map((region) => (
                <option key={region.value} value={region.value}>
                  {region.label}
                </option>
              ))}
            </Form.Select>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "14px" }}>Region</Form.Label>
              <Form.Control
                className="p-2"
                type="text"
                placeholder="Enter District Name"
                autoFocus
                name="name"
                value={editDistrictData.name}
                onChange={handleFormChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded-5"
            variant="secondary"
            onClick={handleClose}
            style={{ fontSize: "14px" }}
          >
            Close
          </Button>
          <Button
            style={{ fontSize: "14px" }}
            className="rounded-5"
            variant="primary"
            onClick={handleUpdateDistrict} // Call handleCreateRegion on button click
          >
            Update district
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditDistrictModal;
