import React from "react";
import AppRouter from "./utils/AppRouter";
import { Toaster } from "react-hot-toast";

function Startup() {
  return (
    <>
      <Toaster />
      <AppRouter />
    </>
  );
}

export default Startup;
