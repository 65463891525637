import { createGroupType } from "models/request/counselling-request";
import { useCreateGroupMutation } from "services/counselling-service";
import { ChangeEvent, useState } from "react";
import toast from "react-hot-toast";
import { Button, Form, Modal } from "react-bootstrap";

const CreateGroup = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [group, setGroup] = useState<createGroupType>({
    name: "",
    description: "",
  });

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setGroup((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [createGroup] = useCreateGroupMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await createGroup(group);
      const { code, message } = response["data"];
      if (code === 0) {
        setGroup({
          name: "",
          description: "",
        });
        toast.success(message);
        handleClose();
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };

  return (
    <>
      <Button
        style={{ fontSize: "14px" }}
        variant="dark"
        className="rounded-5"
        onClick={handleShow}
      >
        Create Group +
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">Create Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Group Name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Enter Group Name"
                name="name"
                onChange={handleFormChanged}
                value={group.name}
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Group Description</Form.Label>
              <Form.Control
                onChange={handleFormChanged}
                name="description"
                as="textarea"
                value={group.description}
                rows={3}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button
            className="rounded-5"
            variant="primary"
            onClick={handleFormSubmit}
            disabled={!group.name || !group.description}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateGroup;
