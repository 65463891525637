import { ussdUser } from "@models/request/login-user.request";
import { useUssdContinueMutation } from "services/user-service";
import { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import toast from "react-hot-toast";
import { GoArrowRight } from "react-icons/go";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FiEye, FiEyeOff } from "react-icons/fi";

const ContinueUssdRegisration: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<ussdUser>({
    mode: "all",
    defaultValues: {
      phone: location.state ? location.state.phone : "",
      email: "",
      password: "",
      confirmPassword: "",
    },
  });
  const [isOTPLoading, setIsOTPLoading] = useState(false);
  const [ussdcontinue] = useUssdContinueMutation();



  const handleFormSubmit = async (formData: ussdUser) => {
    setIsOTPLoading(true);

    try {
      const response = await ussdcontinue(formData);
      const { code, message } = response["data"];
      if (code === 201) {
        toast.success(message);
        navigate("/auth/login");
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    } finally {
      setIsOTPLoading(false);
    }
  };
  return (
    <Container
      className="d-flex align-items-center justify-content-center "
      style={{ height: "90%" }}
    >
      <div className="w-100 px-lg-5">
        <div className="mt-3 ">
          <h2 className="fw-bolder mb-5">Continue USSD Registration</h2>
        </div>

        <Form onSubmit={handleSubmit(handleFormSubmit)}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control
              type="email"
              placeholder="Email"
              name="email"
              {...register("email", {
                required: { value: true, message: "Email is required" },

                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Email format is invalid",
                },
              })}
            />
            <p className="text-danger">{errors.email?.message}</p>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <div className="d-flex align-items-center">
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"

                name="password"
                {...register("password", {
                  required: "Password is required",
                  pattern: {
                    value:
                      /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[A-Za-z0-9]).{8,}$/,
                    message:
                      "It should have at least 8 characters and special character",
                  },
                })}
              />
              {showPassword ? (
                <FiEyeOff
                  className="eye-icon"
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <FiEye
                  className="eye-icon"
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </div>
            <p className="text-danger">{errors.password?.message}</p>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <div className="d-flex align-items-center">
              <Form.Control
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm password"
                name="confirmPassword"
                {...register("confirmPassword", {
                  required: "Confirm Password is required",
                  validate: (value) =>
                    value === getValues("password") ||
                    "The passwords do not match",
                })}
              />
              {showConfirmPassword ? (
                <FiEyeOff
                  className="eye-icon"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              ) : (
                <FiEye
                  className="eye-icon"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              )}
            </div>
            <p className="text-danger">{errors.confirmPassword?.message}</p>
          </Form.Group>
          <div className="mt-3">
            <Button variant="dark" type="submit" className="rounded-5 w-100">
              {isOTPLoading ? "Submitting..." : "Submit"}
              <GoArrowRight className="mx-3" />
            </Button>
          </div>
        </Form>
      </div>
    </Container>
  );
};

export default ContinueUssdRegisration;
