import { useGetEventPartnersListQuery } from "services/event-service";
import { OnchangeProps } from "models/request/create-event.request";
import { Form } from "react-bootstrap";

interface HostType {
  id: number;
  name: string;
}

interface hostProps extends OnchangeProps {
  hostValue: number;
}

const Host: React.FC<hostProps> = ({ name, onChange, hostValue }) => {
  const { data: response } = useGetEventPartnersListQuery({
    page: 0,
    size: 99999999,
  });

  const users: HostType[] = (response?.data[0]?.data ?? []) as HostType[];

  const HostOptions: { value: number; label: string }[] = users.map(
    (hosts) => ({
      value: hosts.id,
      label: hosts.name,
    })
  );

  return (
    <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
      <Form.Select
        className="event-details-form"
        aria-label="Default select example"
        name={name}
        onChange={onChange}
        value={hostValue}
      >
        <option className="text-muted" value="" selected disabled>
          Select host
        </option>
        {HostOptions.map((host) => (
          <option key={host.value} value={host.value}>
            {host.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

export default Host;
