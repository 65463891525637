import { GridColDef } from "@mui/x-data-grid";
import { Image, Row, Button } from "react-bootstrap";
import LeaveGroup from "../../../groups/components/removeMember/RemoveMember";
import ViewDetails from "../ViewDetails";

const ApprovedTableElements: GridColDef[] = [
  {
    field: "id",
    headerName: "Id",
    flex: 1,
    headerClassName: "header--bg",
  },

  {
    field: "requestedBy",
    headerName: "Requested By",
    headerClassName: "header--bg",
    flex: 1,
    renderCell: (params: any) => (
      <>
        <Image
          src={`https://ui-avatars.com/api/?name=${params?.row?.requestedBy[0]?.firstName}+${params?.row?.requestedBy[0].lastName}&background=random&size=32`}
          alt="Avatar"
          roundedCircle
          className="me-2"
        />
        {params?.row?.requestedBy[0]?.firstName}{" "}
        {params?.row?.requestedBy[0]?.lastName}
      </>
    ),
  },

  {
    field: "groupId",
    headerName: "Group Id",
    flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "groupName",
    headerName: "Group Name",
    flex: 1,
    headerClassName: "header--bg",
  },

  {
    field: "createdOn",
    headerName: "Date Created",
    headerClassName: "header--bg",
    flex: 1,
    valueFormatter: (params: any) =>
      new Date(params.value).toLocaleDateString(),
  },

  {
    field: "actions",
    headerName: "Actions",
    headerClassName: "header--bg",
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      console.log("params", params);
      return (
        <div>
          <ViewDetails request={params.row} />
        </div>
      );
    },
  },
];

export default ApprovedTableElements;
