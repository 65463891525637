import { useGetUsersListQuery } from "services/user-service";
import Select from "react-select";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useState } from "react";
import {
  useCreateEventParticipantsMutation,
  useDeleteEventParticipantsMutation,
} from "services/event-service";
import { GrFormClose } from "react-icons/gr";

const AdminParticipantEvent = ({ EventId, eventparticipants }) => {
  const { data: users, isLoading } = useGetUsersListQuery({
    page_index: 0,
    page_size: 99999999,
  });

  console.log("eventparticipants", eventparticipants);
  const participants = users?.data || [];

  const roles = sessionStorage.getItem("roles");
  const allowedRoles = ["Admin", "Super Admin", "Comms (Communications)"];
  const hasAllowedRole =
    roles && allowedRoles.some((role) => roles.includes(role));

  const participantsOptions = participants.map((user) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName}`,
  }));

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      border: "none",
      borderRadius: "2rem",
    }),
  };

  const [selectedParticipant, setSelectedParticipant] = useState(null);

  const [participate] = useCreateEventParticipantsMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!selectedParticipant) {
        toast.error("Please select a participant.");
        return;
      }
      const response = await participate({
        name: selectedParticipant?.label,
        userId: selectedParticipant?.value,
        eventId: EventId,
      });
      const { code, message } = response["data"];
      if (code === "00") {
        toast.success(
          `${selectedParticipant?.label} has been added to this event`
        );
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error:", error);
    }
  };

  const [deleteEventParticipants] = useDeleteEventParticipantsMutation();

  const handleUnregister = async (userId) => {
    try {
      const response = await deleteEventParticipants({
        eventId: EventId,
        participantId: userId,
      });
      const { code, message } = response["data"];
      console.log("Response:", response);
      if (code === "00") {
        const removedParticipant = eventparticipants.find(
          (participant) => participant.userId === userId
        );
        toast.success(
          `${removedParticipant.name} has been removed from this event`
        );
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };

  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);

  return (
    <Row className="">
      <Card.Text className="full-details-event-description m-0">
        <div className="d-flex align-items-center">
          <h5 className="py-1 w-25 m-0 event-label">Participants</h5>

          <hr className="hr w-75" />
        </div>
        <Badge
          pill
          bg="light"
          style={{ fontSize: "13px" }}
          className="text-dark ms-auto fw-normal"
        >
          Total : {eventparticipants.length}
        </Badge>
        {hasAllowedRole && (
          <>
            {eventparticipants.length > 0
              ? eventparticipants.map((participant) => (
                  <Badge
                    pill
                    bg={userId === participant.userId ? "dark" : "light"}
                    text={userId === participant.userId ? "white" : "dark"}
                    className="me-1 p-2 fw-normal"
                    key={participant.userId}
                    style={{ fontSize: "12px" }}
                  >
                    {participant.name}

                    <GrFormClose
                      onClick={() => handleUnregister(participant.userId)}
                      className="ms-1 fs-6 text-dark"
                    />
                  </Badge>
                ))
              : null}
          </>
        )}
      </Card.Text>
      {hasAllowedRole && (
        <div>
          <Col className="mt-3">
            <Select
              options={participantsOptions}
              isDisabled={isLoading}
              placeholder="Add Participant to Events"
              isLoading={isLoading}
              styles={customStyles}
              onChange={setSelectedParticipant}
            />
          </Col>
          <Row className="mt-2">
            <Col className="d-grid">
              <Button
                onClick={handleFormSubmit}
                variant="dark"
                style={{ fontSize: "14px", borderRadius: "2rem" }}
              >
                Add +
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </Row>
  );
};

export default AdminParticipantEvent;
