import { Container, Row, Col, InputGroup, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { IoIosArrowRoundForward } from "react-icons/io";
import { FaLink } from "react-icons/fa6";
import { GrLocation } from "react-icons/gr";
import { ChangeEvent, useState } from "react";
import { CreateEvent } from "models/request/create-event.request";
import FileUpload from "./FileUpload";
import DateCalender from "./DateCalender";
import Collaborators from "./Collaborators";
import Host from "./Host";
import MediaHouse from "./MediaHouse";
import { useNavigate } from "react-router-dom";
import RegionDistrict from "./RegionDistrict";
import Facilitators from "./Facilators";
import { useForm } from "react-hook-form";

type FormControlChangeEvent = ChangeEvent<HTMLInputElement>;

const CreateEventDetails = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);

  const [eventData, setEventData] = useState<CreateEvent>({
    title: "",
    category: "",
    location: {
      city: "",
      districtId: "",
      nameOfVenue: "",
      latitude: "",
      longitude: "",
    },
    participants: [],
    mediaHouses: [],
    collaborators: [],
    facilitators: [],
    host: 0,
    stime: "",
    etime: "",
    startDay: "",
    endDay: "",
    image: "",
    link: "",
    description: "",
    createdBy: userId,
    public: false,
  });

  console.log("event data to be sent:: ", eventData);

  const [eventType, setEventType] = useState<string>("");

  const handleFormChanged = (e: FormControlChangeEvent) => {
    const { name, value } = e.target;

    // Check if the field is part of the location object
    if (name.startsWith("location.")) {
      const locationField = name.split(".")[1];
      setEventData((prevEventData) => ({
        ...prevEventData,
        location: {
          ...prevEventData.location,
          [locationField]: value,
        },
      }));
    } else {
      setEventData((prevEventData) => ({
        ...prevEventData,
        [name]: value,
      }));
    }
  };

  const handleFormSelectChanged: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    const { name, value } = e.target;

    // Check if the field is part of the location object
    if (name.startsWith("location.")) {
      const locationField = name.split(".")[1];
      setEventData((prevEventData) => ({
        ...prevEventData,
        location: {
          ...prevEventData.location,
          [locationField]: value,
        },
      }));
    } else {
      setEventData((prevEventData) => ({
        ...prevEventData,
        [name]: value,
      }));
      if (name === "category") {
        setEventType(value);
        if (value === "VIRTUAL_SESSION") {
          setEventData((prevEventData) => ({
            ...prevEventData,
            location: null,
          }));
        }
      }
    }
  };

  const handleFacilitatorSelectChange = (selectedOptions) => {
    setEventData((prevEventData) => ({
      ...prevEventData,
      facilitators: selectedOptions.map((option) => option.value),
    }));
  };

  const handleCollaboratorsSelectChange = (selectedOptions) => {
    setEventData((prevEventData) => ({
      ...prevEventData,
      collaborators: selectedOptions.map((option) => option.value),
    }));
  };

  const handleMediaSelectChange = (selectedOptions) => {
    setEventData((prevEventData) => ({
      ...prevEventData,
      mediaHouses: selectedOptions.map((option) => option.value),
    }));
  };

  const handleImageChanged = (imageInfo: {
    target: { name: string; value: string };
  }) => {
    console.log(
      "Image changed:",
      imageInfo.target.name,
      imageInfo.target.value
    );
    setEventData((prevEventData) => ({
      ...prevEventData,
      [imageInfo.target.name]: imageInfo.target.value,
    }));
  };

  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const handleDateChange = (item) => {
    const startDate = item.selection.startDate.toISOString().split("T")[0];
    const endDate = item.selection.endDate
      ? item.selection.endDate.toISOString().split("T")[0]
      : null;
    setEventData((prevEventData) => ({
      ...prevEventData,
      startDay: startDate,
      endDay: endDate,
    }));
    setDate([item.selection]);

    console.log("Start Date:", startDate);
    console.log("End Date:", endDate);
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    setEventData((prevEventData) => ({
      ...prevEventData,
      [name]: checked,
    }));
  };

  return (
    <Container>
      <Row className="px-3">
        <Col lg={8}>
          <Row>
            <div className="d-flex align-items-center">
              <h5 className="py-3 w-25 m-0 event-details-title">
                Event Details
              </h5>
              <hr className="hr w-75" />
            </div>
            <Row className="m-0 p-0">
              <Col className="m-0 " lg={6}>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="d-flex align-items-center">
                    <Form.Label className="event-label ">Event Name</Form.Label>
                  </div>
                  <Form.Control
                    className="event-details-form"
                    name="title"
                    type="text"
                    {...register("title", {
                      required: "Event Name is required",
                    })}
                    onChange={handleFormChanged}
                  />
                  {errors.title && (
                    <Form.Text className="text-danger">
                      {String(errors.title.message)}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col className="m-0 " lg={6}>
                <div className="d-flex align-items-center">
                  <Form.Label className="event-label">Event type</Form.Label>
                </div>
                <Form.Select
                  className="event-details-form"
                  aria-label="Default select example"
                  name="category"
                  {...register("category", {
                    required: "Event Category is required",
                  })}
                  onChange={handleFormSelectChanged}
                >
                  <option className="text-muted" value="" selected disabled>
                    Select event type
                  </option>

                  <option value="IN_PERSON_SESSION">In-person session</option>
                  <option value="IN_PERSON_SESSION_MENTORSHIP">
                    In-person session mentorship
                  </option>
                  <option value="IN_PERSON_SESSION_COUNSELLING">
                    In-person session counselling
                  </option>
                  <option value="MEDIA_ENGAGEMENT">Media engagement</option>
                  <option value="VIRTUAL_SESSION">Virtual session</option>
                </Form.Select>
                {errors.category && (
                  <Form.Text className="text-danger">
                    {String(errors.category.message)}
                  </Form.Text>
                )}
              </Col>
            </Row>
            {eventType === "MEDIA_ENGAGEMENT" && (
              <Row>
                <Col>
                  <div className="d-flex align-items-center">
                    <h5 className="event-label">Media engagement</h5>
                  </div>
                  <MediaHouse
                    name="mediaHouses"
                    onChange={handleMediaSelectChange}
                    validationRules={
                      eventType === "MEDIA_ENGAGEMENT"
                        ? {
                            required:
                              "Media Houses are required for Media Engagement",
                          }
                        : {}
                    }
                  />
                </Col>
              </Row>
            )}
            {(eventType === "IN_PERSON_SESSION_MENTORSHIP" ||
              eventType === "IN_PERSON_SESSION_COUNSELLING" ||
              eventType === "IN_PERSON_SESSION" ||
              eventType === "MEDIA_ENGAGEMENT" ||
              eventType === "") && (
              <>
                <RegionDistrict
                  name="location.districtId"
                  onChange={handleFormSelectChanged}
                />

                <Row className="m-0 p-0">
                  <Col className="" lg={6}>
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <div className="d-flex align-items-center">
                        <Form.Label className="event-label">City</Form.Label>
                      </div>
                      <Form.Control
                        className="event-details-form"
                        type="text"
                        placeholder="City"
                        name="location.city"
                        onChange={handleFormChanged}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="" lg={6}>
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <div className="d-flex align-items-center">
                        <Form.Label className="event-label">Venue</Form.Label>
                      </div>
                      <InputGroup>
                        <InputGroup.Text>
                          <GrLocation />
                        </InputGroup.Text>
                        <Form.Control
                          className="event-details-form-with-icons"
                          type="text"
                          placeholder="Venue"
                          name="location.nameOfVenue"
                          onChange={handleFormChanged}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}

            <Row className="m-0 p-0">
              <Col className="m-0 " lg={6}>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="d-flex align-items-center">
                    <Form.Label className="event-label ">Start Time</Form.Label>
                  </div>
                  <Form.Control
                    className="event-details-form"
                    type="time"
                    name="stime"
                    {...register("stime", {
                      required: "Event start time is required",
                    })}
                    placeholder="Start Time"
                    onChange={handleFormChanged}
                  />
                </Form.Group>
                {errors.stime && (
                  <Form.Text className="text-danger">
                    {String(errors.stime.message)}
                  </Form.Text>
                )}
              </Col>
              <Col className="m-0 " lg={6}>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <div className="d-flex align-items-center">
                    <Form.Label className="event-label">End Time</Form.Label>
                  </div>
                  <Form.Control
                    className="event-details-form"
                    name="etime"
                    type="time"
                    {...register("etime", {
                      required: "Event end time is required",
                    })}
                    placeholder="End Time"
                    onChange={handleFormChanged}
                  />
                </Form.Group>
                {errors.etime && (
                  <Form.Text className="text-danger">
                    {String(errors.etime.message)}
                  </Form.Text>
                )}
              </Col>
            </Row>

            {(eventType === "VIRTUAL_SESSION" || eventType === "") && (
              <Form.Group
                className="mb-2"
                controlId="exampleForm.ControlInput1"
              >
                <div className="d-flex align-items-center">
                  <Form.Label className="event-label">Meeting Link</Form.Label>
                </div>
                <InputGroup>
                  <InputGroup.Text>
                    <FaLink />
                  </InputGroup.Text>
                  <Form.Control
                    className="event-details-form-with-icons"
                    type="text"
                    name="link"
                    placeholder="Meeting link"
                    {...register("link", {
                      required:
                        eventType === "VIRTUAL_SESSION"
                          ? "Event link is required"
                          : false,
                    })}
                    onChange={handleFormChanged}
                  />
                </InputGroup>
                {errors.link && (
                  <Form.Text className="text-danger">
                    {String(errors.link.message)}
                  </Form.Text>
                )}
              </Form.Group>
            )}
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
              <div className="d-flex align-items-center">
                <Form.Label className="event-label">Description</Form.Label>
              </div>
              <Form.Control
                as="textarea"
                rows={3}
                type="text"
                placeholder="Enter description"
                name="description"
                onChange={handleFormChanged}
                maxLength={255}
                style={{ fontSize: "14px" }}
              />
            </Form.Group>
          </Row>

          <Form>
            <div className="my-3">
              <Form.Check
                type="checkbox"
                label="Public"
                name="public"
                checked={eventData.public}
                onChange={handleCheckboxChange}
              />
            </div>
          </Form>

          <Row>
            <Col>
              <div className="d-flex align-items-center">
                <h5 className="py-3 event-label">Facilitators</h5>
                <hr className="hr  w-100" />
              </div>
              <div>
                <Facilitators
                  name="facilitators"
                  onChange={handleFacilitatorSelectChange}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex align-items-center">
                <h5 className="py-3  event-label">Collaborators</h5>
                <hr className="hr  w-100" />
              </div>
              <div>
                <Collaborators
                  name="collaborators"
                  onChange={handleCollaboratorsSelectChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="mb-2">
              <div className="d-flex align-items-center">
                <h5 className="py-3 m-0 event-label">Host</h5>
              </div>
              <div>
                <Host name="host" onChange={handleFormSelectChanged} />
              </div>
            </Col>
          </Row>
        </Col>

        <Col
          className="px-lg-4 d-flex flex-column justify-content-between"
          lg={4}
        >
          <div className="mx-auto">
            <DateCalender date={date} onDateChange={handleDateChange} />
            <FileUpload name="image" onChange={handleImageChanged} />
          </div>
          <div>
            <Row className="px-4 pb-4">
              <Col className="m-0 p-0 d-flex justify-content-end align-items-center">
                <Button
                  variant="dark"
                  className="event-details-button fs-6 rounded-5"
                  onClick={handleSubmit((formData) => {
                    // Include fields from formData directly into eventData
                    const updatedEventData = {
                      ...eventData,
                      ...formData,
                    };

                    navigate("/events/publish", {
                      state: { eventData: updatedEventData },
                    });
                  })}
                >
                  Publish event <IoIosArrowRoundForward />
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateEventDetails;
