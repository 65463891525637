import { useEditGroupMutation } from "services/counselling-service";
import { editGroupType } from "models/request/counselling-request";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { Form } from "react-bootstrap";

const EnableGroup = ({ groupId, groupEnabled }) => {
  const [editgroup, setEditGroup] = useState<editGroupType>({
    id: groupId,
    enabled: true,
  });

  const [editGroup] = useEditGroupMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setEditGroup((prevState) => ({
      ...prevState,
      enabled: e.target.checked,
    }));

    try {
      const response = await editGroup(editgroup);
      const { code, message } = response["data"];
      if (code === 0) {
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };
  return (
    <div>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label style={{fontSize: "12px"}}>Enable Group</Form.Label>
        <Form.Check
          type="switch"
          id="custom-switch"
          name="enabled"
          checked={groupEnabled}
          onChange={handleFormSubmit}
        />
      </Form.Group>
    </div>
  );
};

export default EnableGroup;
