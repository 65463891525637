import { GridColDef } from "@mui/x-data-grid";
import EditRegionModal from "../components/EditRegionModal";
import DeleteRegionModal from "../components/DeleteRegionModal";

const RegionTable: GridColDef[] = [
  {
    field: "id",
    headerName: "Id",
    flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "createdOn",
    headerName: "Created On",
    headerClassName: "header--bg",
    flex: 1,
    valueFormatter: (params: any) => new Date(params?.value).toLocaleDateString(),
  },
  {
    field: "updatedOn",
    headerName: "Updated On",
    flex: 1,
    headerClassName: "header--bg",
    valueFormatter: (params: any) => new Date(params?.value).toLocaleDateString(),
  },
  {
    field: "actions",
    headerName: "Actions",
    headerClassName: "header--bg",
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      console.log("params", params);
      return (
        <div>
          <EditRegionModal regData={params?.row} />
          <DeleteRegionModal regID={params?.id} />
        </div>
      );
    },
  },
];

export default RegionTable;
