import { Spinner } from "react-bootstrap";

const PageLoader = () => {

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Spinner
        animation="border"
        variant="secondary"
        style={{ height: "50px", width: "50px" }}
      />
    </div>
  );
}

export default PageLoader;