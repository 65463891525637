import {
  CalenderIcon,
  Clock,
  GameConsole,
  LocationIcon,
  UserIcon,
} from "presentation/assets";
import {
  Card,
  Col,
  Container,
  Row,
  Image,
  Button,
  Badge,
} from "react-bootstrap";
import { IoShareSocialOutline } from "react-icons/io5";
import { CiVideoOn } from "react-icons/ci";
import AdminParticipantEvent from "./AdminParticipantEvent";
import UserReviews from "./UserReviews";
import AdminReviews from "./AdminReviews";
import { useEffect, useState } from "react";
import AdminExpectaions from "./AdminExpectaions";
import UserExpectations from "./UserExpectaions";

const FullDetailsEventBody = ({
  eventId,
  eventtitle,
  eventimage,
  eventcategory,
  eventregion,
  eventdistrict,
  eventcity,
  eventvenue,
  eventstartday,
  eventendday,
  eventstime,
  eventetime,
  eventdescription,
  eventlink,
  eventhost,
  eventcollaborators,
  eventmediahouses,
  eventparticipants,
  eventcheckin,
  eventstatus,
  eventfacilitators,
  eventexpectations,
  eventreviews,
}) => {
  const roles = sessionStorage.getItem("roles");
  const allowedRoles = ["Admin", "Super Admin", "Comms (Communications)"];
  const hasAllowedRole =
    roles && allowedRoles.some((role) => roles.includes(role));

  const isEventInProgress =
    eventstatus === "STARTED" || eventstatus === "ENDED";

  const isEventUpcoming = eventstatus === "UPCOMING";

  const [phoneNumber] = useState(sessionStorage.getItem("phoneNumber"));

  const [hasCheckedIn, setHasCheckedIn] = useState(false);
  const checkUserCheckIn = () => {
    const userCheckIn = eventcheckin.some(
      (checkin) => checkin.phone === phoneNumber
    );
    setHasCheckedIn(userCheckIn);
    localStorage.setItem(`hasCheckedIn_${eventId}`, userCheckIn);
    console.log(`hasCheckedIn_${eventId}`, userCheckIn);
  };
  // sessionStorage.setItem("hasCheckedIn", hasCheckedIn);

  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);

  // Filter eventparticipants to only include the current user
  const [hasRegistered, setHasRegistered] = useState(false);
  const checkRegistered = () => {
    const userRegistered = eventparticipants.some(
      (participant) => participant.userId === userId
    );
    setHasRegistered(userRegistered);
  };

  // Call the function to check the user's check-in status
  useEffect(() => {
    checkUserCheckIn();
    checkRegistered();
  }, [eventcheckin, phoneNumber, eventparticipants, userId]);

  return (
    <Container>
      <Row className="my-4">
        <Col lg={8}>
          <Card className="bg-transparent border-0">
            <Row className="g-0 flex-wrap flex-column flex-md-row">
              {eventimage && (
                <Col>
                  <Image
                    alt="Event Image"
                    src={eventimage}
                    className="img-fluid   object-fit-cover rounded-3 "
                    style={{ width: "500px" }}
                  />
                </Col>
              )}
              <Col>
                <Card.Body>
                  <Card.Title className="full-details-event-title">
                    {eventtitle}
                  </Card.Title>
                  <ul className="list-unstyled full-details-event-card-text mb-0 pt-3">
                    <li className="icon-link d-flex pb-2">
                      <Image src={UserIcon} alt="" width={14} />
                      {eventcategory}
                    </li>
                    {eventcategory !== "VIRTUAL_SESSION" ? (
                      eventcategory === "MEDIA_ENGAGEMENT" ? (
                        <>
                          <li className="icon-link d-flex pb-2">
                            <Image src={LocationIcon} alt="" width={14} />
                            {eventregion} - {eventdistrict} - {eventcity} -{" "}
                            {eventvenue}
                          </li>
                          <li className="icon-link d-flex pb-2 text-decoration-underline">
                            <a
                              href={eventlink || "NA"}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <CiVideoOn className="fs-6 " />
                              {eventlink || "NA"}
                            </a>
                          </li>
                        </>
                      ) : (
                        <>
                          {eventcategory === "IN_PERSON_SESSION_MENTORSHIP" ||
                          eventcategory === "IN_PERSON_SESSION_COUNSELLING" ||
                          eventcategory === "IN_PERSON_SESSION" ? (
                            <li className="icon-link d-flex pb-2">
                              <Image src={LocationIcon} alt="" width={14} />
                              {eventregion} - {eventdistrict} - {eventcity} -{" "}
                              {eventvenue}
                            </li>
                          ) : null}
                        </>
                      )
                    ) : (
                      <li className="icon-link text-decoration-underline">
                        <a
                          href={eventlink || "NA"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <CiVideoOn className="fs-6 me-2 " />
                          {eventlink || "NA"}
                        </a>
                      </li>
                    )}

                    <li className="icon-link d-flex py-2 ">
                      <Image src={CalenderIcon} alt="" width={14} />
                      {eventstartday} - {eventendday}
                    </li>
                    <li className="icon-link d-flex pb-2">
                      <Image src={Clock} alt="" width={14} />
                      {eventstime} - {eventetime}
                    </li>
                  </ul>

                  <div className="d-flex py-2 align-items-center">
                    <Image
                      alt=""
                      className="rounded-circle p-1 bg-light"
                      src={GameConsole}
                      width={24}
                    />
                    <hr className="hr w-100"></hr>
                  </div>
                  <Card.Text className="full-details-event-description">
                    {eventdescription}
                  </Card.Text>
                  <Card.Text className="full-details-event-description m-0">
                    {eventfacilitators.length > 0 && (
                      <div className="d-flex align-items-center">
                        <h5 className="py-1 w-25 m-0 event-label">
                          Facilitators
                        </h5>
                        <hr className="hr w-75" />
                      </div>
                    )}
                    {eventfacilitators.length > 0
                      ? eventfacilitators.map((facilitator, index) => (
                          <Badge
                            pill
                            bg="light"
                            className="me-1 p-2 fw-normal text-dark"
                            key={index}
                            style={{ fontSize: "12px" }}
                          >
                            {facilitator}
                          </Badge>
                        ))
                      : null}
                  </Card.Text>
                  <Card.Text className="full-details-event-description m-0">
                    {eventcollaborators.length > 0 && (
                      <div className="d-flex align-items-center">
                        <h5 className="py-1 w-25 m-0 event-label">
                          Collaborators
                        </h5>
                        <hr className="hr w-75" />
                      </div>
                    )}
                    {eventcollaborators.length > 0
                      ? eventcollaborators.map((collaborator, index) => (
                          <Badge
                            pill
                            bg="light"
                            className="me-1 p-2 fw-normal text-dark"
                            key={index}
                            style={{ fontSize: "12px" }}
                          >
                            {collaborator}
                          </Badge>
                        ))
                      : null}
                  </Card.Text>

                  <Card.Text className="full-details-event-description m-0">
                    <div className="d-flex align-items-center">
                      <h5 className="py-1  w-25 m-0 event-label">Host</h5>
                      <hr className="hr  w-75" />
                    </div>
                    <Badge
                      pill
                      bg="light"
                      className="me-1 p-2 fw-normal text-dark"
                      style={{ fontSize: "12px" }}
                    >
                      {eventhost}
                    </Badge>
                  </Card.Text>
                  <Card.Text className="full-details-event-description m-0">
                    {eventmediahouses.length > 0 && (
                      <div className="d-flex align-items-center">
                        <h5 className="py-1 w-25 m-0 event-label">
                          Media Houses
                        </h5>
                        <hr className="hr w-75" />
                      </div>
                    )}
                    {eventmediahouses.length > 0
                      ? eventmediahouses.map((media, index) => (
                          <Badge
                            pill
                            bg="light"
                            className="me-1 p-2 fw-normal text-dark"
                            key={index}
                            style={{ fontSize: "12px" }}
                          >
                            {media}
                          </Badge>
                        ))
                      : null}
                  </Card.Text>
                  <Card.Text className="full-details-event-description m-0">
                    <div className="d-flex align-items-center">
                      <h5 className="py-1 w-25 m-0 event-label">Check-Ins</h5>
                      <hr className="hr w-75" />
                    </div>

                    <Badge
                      pill
                      bg="light"
                      style={{ fontSize: "13px" }}
                      className="text-dark ms-auto fw-normal"
                    >
                      Total : {eventcheckin.length}
                    </Badge>
                    {hasAllowedRole && eventcheckin.length > 0
                      ? eventcheckin.map((checkin, index) => (
                          <Badge
                            pill
                            bg="light"
                            className="me-1 p-2 fw-normal text-dark"
                            key={index}
                            style={{ fontSize: "12px" }}
                          >
                            {checkin.firstName} {checkin.lastName}
                          </Badge>
                        ))
                      : null}
                  </Card.Text>

                  <AdminParticipantEvent
                    EventId={eventId}
                    eventparticipants={eventparticipants}
                  />
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col lg={4}>
          {isEventInProgress && (
            <>
              {hasAllowedRole ? (
                <AdminReviews eventReviews={eventreviews} />
              ) : (
                <>{hasCheckedIn && <UserReviews EventId={eventId} />}</>
              )}
            </>
          )}
          {isEventUpcoming && (
            <>
              {hasAllowedRole ? (
                <AdminExpectaions EventExpectation={eventexpectations} />
              ) : (
                <>{hasRegistered && <UserExpectations EventId={eventId} />}</>
              )}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default FullDetailsEventBody;
