import FullDetailsEventHeader from "./components/FullDetailsEventHeader";
import FullDetailsEventBody from "./components/FullDetailsEventBody";
import PageLoader from "presentation/components/PageLoader";
import { useGetEventByIdQuery } from "services/event-service";
import { useParams } from "react-router-dom";

const FullDetailsEvent = () => {
  const { id } = useParams();
  const eventid = Number(id);

  const { data: response, isLoading } = useGetEventByIdQuery(eventid);
  const eventData = response?.data?.[0];

  const eventTitle = eventData?.title || "N/A";
  const eventImage = eventData?.image || "";
  const eventCategory = eventData?.category || "N/A";
  const eventRegion = eventData?.location?.district?.region?.name || "N/A";
  const eventDistrict = eventData?.location?.district?.name || "N/A";
  const eventCity = eventData?.location?.city || "N/A";
  const eventVenue = eventData?.location?.nameOfVenue || "N/A";
  const eventStartDay = eventData?.startDay || "N/A";
  const eventEndDay = eventData?.endDay || "N/A";
  const eventStime = eventData?.stime || "N/A";
  const eventEtime = eventData?.etime || "N/A";
  const eventStatus = eventData?.status || "N/A";
  const eventDescription = eventData?.description || "N/A";
  const eventLink = eventData?.link || "N/A";
  const eventHost = eventData?.host?.name || "N/A";
  const eventExpectations = eventData?.expectations;
  const eventReviews = eventData?.reviews;
  const eventCollaborators =
    eventData?.collaborators?.map((collaborator) => collaborator.name) || [];
  const eventMediaHouses =
    eventData?.mediaHouses?.map((mediaHouse) => mediaHouse.name) || [];
  const eventParticipant = (eventData?.participants || []).map(
    (participant) => ({
      name: participant.name,
      userId: participant.userId,
    })
  );
  const eventCheckIn = (eventData?.checkIns || []).map((checkIn) => ({
    firstName: checkIn.participantFirstName,
    lastName: checkIn.participantLastName,
    phone: checkIn.participantPhoneNumber,
  }));

  const eventFacilitators = Array.isArray(eventData?.facilitators)
    ? eventData.facilitators
    : [];

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div>
      <FullDetailsEventHeader
        eventtitle={eventTitle}
        eventId={eventid}
        eventstatus={eventStatus}
        eventParticipantsWithUserId={eventParticipant.map(
          (participant) => participant.userId
        )}
      />
      <FullDetailsEventBody
        eventId={eventid}
        eventtitle={eventTitle}
        eventimage={eventImage}
        eventcategory={eventCategory}
        eventregion={eventRegion}
        eventdistrict={eventDistrict}
        eventcity={eventCity}
        eventvenue={eventVenue}
        eventstartday={eventStartDay}
        eventendday={eventEndDay}
        eventstime={eventStime}
        eventetime={eventEtime}
        eventdescription={eventDescription}
        eventlink={eventLink}
        eventhost={eventHost}
        eventcollaborators={eventCollaborators}
        eventmediahouses={eventMediaHouses}
        eventparticipants={eventParticipant}
        eventcheckin={eventCheckIn}
        eventstatus={eventStatus}
        eventfacilitators={eventFacilitators}
        eventexpectations={eventExpectations}
        eventreviews={eventReviews}
      />
    </div>
  );
};

export default FullDetailsEvent;
