import Select from "react-select";
import { OnchangeProps } from "models/request/create-event.request";
import { useGetAllFacilitatorsQuery } from "services/auth-service";


const Facilitators : React.FC<OnchangeProps> = ({ name, onChange }) => {
  const { data: response, isLoading } = useGetAllFacilitatorsQuery({
    page_index: 0,
    page_size: 99999999,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const users =  response?.data

  const facilitatorOptions: { value: string; label: string }[] = users.map(
    (user) => ({
      value: user.username,
      label: `${user.firstName} ${user.lastName}`,
    })
  );

  return (
    <div style={{ fontSize: "14px" }}>
      <Select
        className="basic-multi-select"
        classNamePrefix="select"
        options={facilitatorOptions}
        isMulti
        name={name}
        onChange={onChange}
      />
    </div>
  );
};

export default Facilitators;
