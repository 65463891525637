import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDeleteEventDistrictByIdMutation } from "services/event-service";

const DeleteDistrictModal = ({ distID }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [deleteDistrictMutation] = useDeleteEventDistrictByIdMutation();

  const handleDeleteDistrict = async () => {
    try {
      // Call the delete user mutation with the user ID
      const response = await deleteDistrictMutation(distID);
      handleClose(); // Close the modal after successful deletion
      const { code, message } = response["data"];
      if (code === "00") {
        toast.success(message);
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error(error.message);
    }
  };

  return (
    <>
      <RiDeleteBin6Line
        className="text-danger"
        onClick={handleShow}
        style={{ fontSize: "20px", cursor: "pointer" }}
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Delete district</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this district?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="dark"
            className="users-text rounded-5"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            className="users-text rounded-5"
            variant="danger"
            onClick={handleDeleteDistrict}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteDistrictModal;
