import {
  useAvailabilitySlotMutation,
  useAvailabilitySlotTimeMutation,
  useCounsellorSlotMutation,
} from "services/counselling-service";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import {
  CounsellorAddSlot,
  slotRequest,
  slotTimeRequest,
} from "@models/request/counselling-request";
import { GoArrowRight } from "react-icons/go";
import { VscAdd } from "react-icons/vsc";

const AddSlot = ({ mentorID }) => {
  const [show, setShow] = useState(false);
  const [responseSlotTimeId, setResponseSlotTimeId] = useState<number | null>(
    null
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //   Mentor Available Slot Time
  const [slot, setSlot] = useState<slotTimeRequest>({
    value: 0,
    unit: "",
  });

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSlot((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFormSelectChanged = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSlot((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [availabilitySlotTime] = useAvailabilitySlotTimeMutation();

  const handleCreateSlotTime = async (e) => {
    e.preventDefault();
    try {
      const response = await availabilitySlotTime(slot);
      const { code, message, data } = response["data"];
      if (code === 0) {
        toast.success(message);
        setResponseSlotTimeId(data.id);
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating counsellor:", error);
    }
  };

  useEffect(() => {
    // Update availableSlot state when responseId changes
    if (responseSlotTimeId !== null) {
      setAvailableSlot((prevFormData) => ({
        ...prevFormData,
        timeId: responseSlotTimeId,
      }));
    }
  }, [responseSlotTimeId]);

  //   Mentor Available Slot
  const [responseSlotId, setResponseSlotId] = useState<number | null>(null);
  const [availableSlot, setAvailableSlot] = useState<slotRequest>({
    timeId: responseSlotTimeId,
    startTime: "",
    endTime: "",
  });

  console.log("availableSlot", availableSlot);

  const handleFormAvailableSlotChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAvailableSlot((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [availabilitySlot] = useAvailabilitySlotMutation();

  const handleCreateAvailableSlot = async (e) => {
    e.preventDefault();
    try {
      const response = await availabilitySlot(availableSlot);
      const { code, message, data } = response["data"];
      if (code === 0) {
        toast.success(message);
        setResponseSlotId(data.id);
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating counsellor:", error);
    }
  };

  useEffect(() => {
    // Update availableSlot state when responseId changes
    if (responseSlotId !== null) {
      setCounsellorSlot((prevFormData) => ({
        ...prevFormData,
        slotId: responseSlotId,
      }));
    }
  }, [responseSlotId]);

  //   Counsellor Add-Slot
  const [counsellorSlot, setCounsellorSlot] = useState<CounsellorAddSlot>({
    mentorId: mentorID,
    slotId: responseSlotId,
  });
  const [CounsellingSlot] = useCounsellorSlotMutation();

  const handleCounsellingAddSlot = async (e) => {
    e.preventDefault();
    try {
      const response = await CounsellingSlot(counsellorSlot);
      const { code, message, data } = response["data"];
      if (code === 0) {
        toast.success(message);
        handleClose();
        handleClose();
        // Reset slot and availableSlot to initial values
        setSlot({ value: 0, unit: "" });
        setAvailableSlot({ timeId: null, startTime: "", endTime: "" });
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating counsellor:", error);
    }
  };

  return (
    <>
      <Button
        className="py-2 mx-1 rounded-5 "
        variant="light"
        style={{ fontSize: "12px", background: "rgba(25, 22, 29, 0.06)" }}
        onClick={handleShow}
      >
        Add slot +
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Availability Slot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "14px" }}>Slot Time</Form.Label>
              <InputGroup className="mb-3">
                <Form.Select
                  name="unit"
                  onChange={handleFormSelectChanged}
                  aria-label="Default select example"
                >
                  <option value="" selected disabled>
                    Select Unit
                  </option>
                  <option value="HOURLY">Hourly</option>
                  <option value="DAILY">Daily</option>
                  <option value="WEEKLY">Weekly</option>
                  <option value="BI_WEEKLY">Bi-Weekly</option>
                  <option value="MONTHLY">Monthly</option>
                  <option value="QUARTERLY">Quartely</option>
                  <option value="BI_ANNUALLY">Bi-Annually</option>
                  <option value="YEARLY">Yearly</option>
                </Form.Select>
                <Form.Control
                  aria-label="value"
                  type="number"
                  placeholder="Enter Value"
                  name="value"
                  onChange={handleFormChanged}
                />
                <Button
                  style={{ fontSize: "12px" }}
                  className="rounded-circle "
                  variant="dark"
                  id="button-addon2"
                  onClick={handleCreateSlotTime}
                  disabled={!slot.unit || !slot.value}
                >
                  <VscAdd />
                </Button>
              </InputGroup>
            </Form.Group>
          </Form>
          {responseSlotTimeId !== null && (
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "14px" }}>
                Availability Slot
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="value"
                  type="time"
                  placeholder="Start Value"
                  name="startTime"
                  onChange={handleFormAvailableSlotChanged}
                />
                <Form.Control
                  aria-label="value"
                  type="time"
                  placeholder="End Value"
                  name="endTime"
                  onChange={handleFormAvailableSlotChanged}
                />

                <Button
                  style={{ fontSize: "12px" }}
                  className="rounded-circle "
                  variant="dark"
                  id="button-addon2"
                  onClick={handleCreateAvailableSlot}
                  disabled={!availableSlot.startTime || !availableSlot.endTime}
                >
                  <VscAdd />
                </Button>
              </InputGroup>
            </Form.Group>
          </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded-5"
            variant="light"
            onClick={handleClose}
            style={{ fontSize: "14px" }}
          >
            Cancel
          </Button>
          <Button
            style={{ fontSize: "14px" }}
            className="rounded-5"
            variant="dark"
            onClick={handleCounsellingAddSlot}
            disabled={!counsellorSlot.slotId}
          >
            Submit <GoArrowRight />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddSlot;
