import React from "react";
import { Button, Container, Row, Col, Card } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import toast from "react-hot-toast";
import { useRegisterGroupMentorshipMutation } from "services/counselling-service";

const RequestToJoin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const groupData = location.state?.groupData;
  const [registerGroup] = useRegisterGroupMentorshipMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await registerGroup(groupData);
      const { code, message } = response["data"];
      if (code === 0) {
        toast.success(message);
        navigate("/e-mentoring/groups");
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };

  return (
    <div>
      <div className="bg-enquiries">
        <Container className="vh-100 d-flex align-items-center justify-content-center">
          <Row className="justify-content-center">
            <Col md={12} xs={12}>
              <div className="py-13 pt-lg-20 pb-lg-20 text-center">
                <h1 className="display-3 fw-medium text-white mb-5">
                  Request to join!
                </h1>
                <div
                  className="modal show rounded-1"
                  style={{ display: "block", position: "initial" }}
                >
                  <Col md={7} className="mx-auto">
                    <Card className="text-center">
                      <Card.Body>
                        <Card.Text className="my-3">
                          Are you sure you want to join this group? if you join,
                          an admin has to approve your request!
                        </Card.Text>

                        <Row>
                          <Col className="text-center mt-5 mb-3">
                            <Button
                              variant="light"
                              className="rounded-5 me-2"
                              onClick={() => navigate(-1)}
                            >
                              Go back <RiArrowLeftLine />
                            </Button>
                            <Button
                              variant="dark"
                              type="submit"
                              onClick={handleFormSubmit}
                              className="rounded-5"
                            >
                              Join Group <RiArrowRightLine />
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default RequestToJoin;
