import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import UnassignedCounsellingDataTable from "./UnassignedCounsellingDataTable";
import AssignedCounsellingDataTable from "./AssignedCounsellingDataTable";

const CousellingTab = ({ searchQuery }) => {
  const [key, setKey] = useState("unassigned");
  return (
    <div>
      {" "}
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="unassigned" title="Unassigned">
          <UnassignedCounsellingDataTable searchQuery={searchQuery} />
        </Tab>
        <Tab eventKey="assigned" title="Assigned">
        <AssignedCounsellingDataTable searchQuery={searchQuery} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default CousellingTab;
