import { Col, Row } from "react-bootstrap";
import MobileSidebar from "./MobileSidebar";

const MobileHeader = () => {
  return (
    <Row className="m-0 p-0">
      <Col className="p-2 m-0">
        <MobileSidebar />
      </Col>
    </Row>
  );
};

export default MobileHeader;
