import React from "react";
import { CSVLink } from "react-csv";
import { CiExport } from "react-icons/ci";

const ExportData = ({ exportfilteredData, resetPage }) => {
  // Function to format the data as neatly arranged strings
  const formatData = (data) => {
    return data.map((user) => {
      return {
        ID: user.id.toString(),
        FirstName: user.firstName,
        MiddleName: user.middleName,
        LastName: user.lastName,
        Email: user.email,
        PhoneNumber: user.phone,
        WhatsappNumber: user.whatsappNumber,
        Age: user.age,
        Gender: user.gender,
        HighestLevelOfEducation: user.highestLevelOfEducation,
        SpringboardCategory: user.springboardCategory,
        DisabilityStatus: user.disabilityStatus,
        RegistrationChannel: user.registrationChannel,
        CreatedOn: new Date(user.createdOn).toLocaleDateString(),
        UpdateOn: new Date(user.updateOn).toLocaleDateString(),
      };
    });
  };

  const dataToExport = formatData(exportfilteredData || []);
  const headers = Object.keys(dataToExport[0] || {});
  const csvData = dataToExport.map((user) => Object.values(user));
  csvData.unshift(headers.map((header) => header.toString()));

  const handleExportClick = () => {
    // Reset the page index when export link is clicked
    resetPage();
  };

  return (
    <div>
      <CSVLink
        data={csvData}
        filename="users.csv"
        className="btn rounded-5 mx-1"
        target="_blank"
        style={{
          fontSize: "12px",
          background: "rgba(25, 22, 29, 0.06)",
        }}
        onClick={handleExportClick}
      >
        Export <CiExport />
      </CSVLink>
    </div>
  );
};

export default ExportData;
