import React, { useContext } from "react";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AuthContext from "../auth/utils/AuthContext";

const UnauthorizedPage = () => {
  const { logout } = useContext(AuthContext);
  let navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate("/auth/login");
  };
  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col md="6">
          <Alert variant="danger">
            <Alert.Heading>
              Sorry, you are not authorized to access this page
            </Alert.Heading>
            <p>Please contact your administrator for further assistance.</p>
            <Button variant="primary" onClick={handleLogout}>
              Logout
            </Button>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default UnauthorizedPage;
