import { useUpdateRegionMutation } from "services/event-service";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { editRegionType } from "models/request/create-event.request";
import toast from "react-hot-toast";

const EditRegionModal = ({ regData }) => {
  const [show, setShow] = useState(false);

  const [editRegionData, setEditRegionData] = useState<editRegionType>({
    id: regData.id,
    name: regData.name,
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setEditRegionData((prevRegionData) => ({
      ...prevRegionData,
      [name]: value,
    }));
  };

  // Apollo hook for mutation
  const [updateRegionMutation] = useUpdateRegionMutation();

  // Function to handle form submission
  const handleUpdateRegion = async (e) => {
    e.preventDefault();
    if (!editRegionData.name.trim()) {
      toast.error("Region must not be empty");
    }
    try {
      // Make the mutation call
      const response = await updateRegionMutation(editRegionData);
      const { code, message } = response["data"];
      if (code === "00") {
        toast.success(message);
      } else {
        toast.error(message || "Something went wrong");
      }
      // Close the modal
      handleClose();
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating region:", error);
    }
  };

  useEffect(() => {
    setEditRegionData((prev) => ({
      ...prev,
      id: regData.id,
      name: regData.name,
    }));
  }, []);

  return (
    <>
      <Button
        className="py-2 rounded-5 mx-auto"
        variant="light"
        style={{ fontSize: "12px", background: "rgba(25, 22, 29, 0.06)" }}
        onClick={handleShow}
      >
        Edit region
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Edit region</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "14px" }}>Region</Form.Label>
              <Form.Control
                className="p-2"
                type="text"
                placeholder="Enter Region Name"
                autoFocus
                name="name"
                value={editRegionData.name}
                onChange={handleFormChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded-5"
            variant="secondary"
            onClick={handleClose}
            style={{ fontSize: "14px" }}
          >
            Close
          </Button>
          <Button
            style={{ fontSize: "14px" }}
            className="rounded-5"
            variant="primary"
            onClick={handleUpdateRegion} // Call handleCreateRegion on button click
          >
            Update region
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditRegionModal;
