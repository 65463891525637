import { useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { CiSquareQuestion } from "react-icons/ci";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { rescheduleRequest } from "models/request/counselling-request";

import { useNavigate } from "react-router-dom";
import { LuRedo2 } from "react-icons/lu";

const RescheduleCounselling = ({ counsellData }) => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [preferredStartAt, setPreferredStartAt] = useState<Date | null>(null);
  const [preferredEndAt, setPreferredEndAt] = useState<Date | null>(null);
  const [rescheduleData, setRescheduleData] = useState<rescheduleRequest>({
    requestId: counsellData?.id,
    preferredStartAT: "",
    preferredEndAt: "",
    participant: true,
  });

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, -5); // Remove .000Z
      setPreferredStartAt(date);
      setRescheduleData((prevFormData) => ({
        ...prevFormData,
        preferredStartAT: formattedDate,
      }));
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, -5); // Remove .000Z
      setPreferredEndAt(date);
      setRescheduleData((prevFormData) => ({
        ...prevFormData,
        preferredEndAt: formattedDate,
      }));
    }
  };

  return (
    <>
      <Button
        className="border-0 bg-transparent p-0 text-black "
        onClick={handleShow}
      >
        <LuRedo2 /> Reschedule
      </Button>

      <Modal
        id="exampleModalToggle2"
        className="border-0"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="border-0  mx-4 my-2" closeButton>
          <Modal.Title className="fs-6">Reschedule Counselling</Modal.Title>
        </Modal.Header>

        <Modal.Body className="mx-4 my-0 py-0">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimePicker"]}>
              <Row>
                <DateTimePicker
                  label="Start date & time"
                  value={preferredStartAt}
                  onChange={handleStartDateChange}
                />
              </Row>
              <Row>
                <DateTimePicker
                  label="End date & time"
                  value={preferredEndAt}
                  onChange={handleEndDateChange}
                />
              </Row>
            </DemoContainer>
          </LocalizationProvider>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="dark"
            className="rounded-5"
            style={{ fontSize: "14px" }}
            onClick={() =>
              navigate("/e-counselling/request/reschedule", {
                state: { rescheduleData },
              })
            }
          >
            Reschedule <CiSquareQuestion />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RescheduleCounselling;
