import { useDeleteEventParticipantsMutation } from "services/event-service";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const UnregisterEvent = ({ eventId }) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [hasCheckedIn] = useState(
    localStorage.getItem(`hasCheckedIn_${eventId}`) === "true"
  );

  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);

  const [deleteEventParticipants] = useDeleteEventParticipantsMutation();

  const handleUnregister = async () => {
    try {
      const response = await deleteEventParticipants({
        eventId: eventId,
        participantId: userId,
      });
      const { code, message, data } = response["data"];
      console.log("Response:", response);
      if (code === "00") {
        toast.success("Event unregistered successfully");
        navigate(`/events`);
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error if needed
    }
  };

  return (
    <>
      <Button
        onClick={handleShow}
        variant="dark event-button fs-6 rounded-5"
        disabled={hasCheckedIn} // Disable the button if hasCheckedIn is true
      >
        Unregister -
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "16px" }}>
            Unregister event
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "14px" }}>
          Are you sure you want to unregister this event?
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button
            style={{ fontSize: "14px" }}
            variant="dark"
            className="rounded-5"
            onClick={handleUnregister}
          >
            Unregister
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UnregisterEvent;
