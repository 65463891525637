import { Container, Navbar, Form, Col, Row, Tabs, Tab } from "react-bootstrap";
import { useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import ProtectedRoutes from "presentation/pages/auth/utils/ProtectedRoutes";
import MyMentorshipGroups from "./components/MyMentorshipGroups";
import AllMentorshipGroups from "./components/AllMentorshipGroups";
import CreateGroup from "./components/CreateGroup";
import DisabledMentorshipGroups from "./components/DisabledMentorshipGroups";

const Groups = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [key, setKey] = useState("allMentorshipGroup");

  const roles = sessionStorage.getItem("roles");
  const allowedRoles = ["Admin", "Super Admin", "Call Center"];
  const hasAllowedRole =
    roles && allowedRoles.some((role) => roles.includes(role));

  return (
    <Container fluid>
      <Navbar expand="lg" className="py-3 p-4 bg-transparent">
        <Navbar.Brand className="fs-4">E-Mentoring</Navbar.Brand>

        <Navbar.Toggle aria-controls="myNavbar2" className="navbar-toggler">
          <RxHamburgerMenu className="text-black fs-4" />
        </Navbar.Toggle>

        <Navbar.Collapse id="myNavbar2">
          <Row className="ms-auto flex-wrap align-items-center">
            <Col className="mx-auto m-0 p-0 position-relative">
              <span className="position-absolute ps-2 pt-2 py-auto">
                <IoSearchOutline />
              </span>
              <Form.Control
                type="search"
                className="py-2 border border-1 rounded-5"
                style={{ paddingLeft: "1.8rem" }}
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Col>
            {hasAllowedRole && (
              <Col className="mx-auto ">
                <CreateGroup />
              </Col>
            )}
          </Row>
        </Navbar.Collapse>
      </Navbar>
      <Row className="bg-white">
        <Col>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            {!hasAllowedRole && (
              <Tab eventKey="myMentorshipGroup" title="My Mentorship Groups">
                <MyMentorshipGroups />
              </Tab>
            )}
            <Tab eventKey="allMentorshipGroup" title="All Mentorship Groups">
              <AllMentorshipGroups />
            </Tab>
            {hasAllowedRole && (
              <Tab
                eventKey="disabledMentorshipGroup"
                title="Disabled Mentorship Groups"
              >
                <DisabledMentorshipGroups />
              </Tab>
            )}
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default ProtectedRoutes(Groups, {
  allowedRoles: [
    "Super Admin",
    "Admin",
    "Participant",
    "Facilitator",
    "Call Center",
    "Volunteer",
  ],
});
