import { editGroupType } from "models/request/counselling-request";
import { useEditGroupMutation } from "services/counselling-service";
import { ChangeEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Button, Form, Modal } from "react-bootstrap";

const EditGroup = ({ groupId, groupName, groupDescription, groupEnabled }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [editgroup, setEditGroup] = useState<editGroupType>({
    id: groupId,
    name: groupName,
    description: groupDescription,
    enabled: groupEnabled,
  });

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditGroup((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFormCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    console.log("Checked:", checked);
    setEditGroup((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
  };

  const [editGroup] = useEditGroupMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await editGroup(editgroup);
      const { code, message } = response["data"];
      if (code === 0) {
        toast.success(message);
        handleClose();
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setEditGroup((prev) => ({
      ...prev,
      id: groupId,
      name: groupName,
      description: groupDescription,
      enabled: groupEnabled,
    }));
  }, []);

  return (
    <>
      <Button
        style={{ fontSize: "14px" }}
        variant="dark"
        className="rounded-5"
        onClick={handleShow}
      >
        Edit Group
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">Edit Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Group Name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Enter Group Name"
                name="name"
                onChange={handleFormChanged}
                value={editgroup.name}
                disabled={editgroup.enabled === false}
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Group Description</Form.Label>
              <Form.Control
                onChange={handleFormChanged}
                name="description"
                as="textarea"
                value={editgroup.description}
                rows={3}
                disabled={editgroup.enabled === false}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Enable or Disable Group</Form.Label>
              <Form.Check
                type="switch"
                id="custom-switch"
                name="enabled"
                // label="Availability"
                checked={editgroup.enabled}
                onChange={handleFormCheck}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button
            className="rounded-5"
            variant="primary"
            onClick={handleFormSubmit}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditGroup;
