import { GridColDef } from "@mui/x-data-grid";
import EditDistrictModal from "../components/EditDistrictModal";
import DeleteDistrictModal from "../components/DeleteDistrictModal";

const DistrictTable: GridColDef[] = [
  {
    field: "id",
    headerName: "Id",
    flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "regionId",
    headerName: "Region Id",
    flex: 1,
    headerClassName: "header--bg",
    valueGetter: (params: any) => params?.row?.region?.id,
  },
  {
    field: "regionName",
    headerName: "Region Name",
    flex: 1,
    headerClassName: "header--bg",
    valueGetter: (params: any) => params?.row?.region?.name,
  },
  {
    field: "createdOn",
    headerName: "Created On",
    headerClassName: "header--bg",
    flex: 1,
    valueFormatter: (params: any) => new Date(params?.value).toLocaleDateString(),
  },
  {
    field: "updatedOn",
    headerName: "Updated On",
    flex: 1,
    headerClassName: "header--bg",
    valueFormatter: (params: any) => new Date(params?.value).toLocaleDateString(),
  },
  {
    field: "actions",
    headerName: "Actions",
    headerClassName: "header--bg",
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      console.log("params", params);
      return (
        <div>
          <EditDistrictModal distData={params?.row} />
          <DeleteDistrictModal distID={params?.row?.id}/>
        </div>
      );
    },
  },
];

export default DistrictTable;
