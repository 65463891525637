import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppConstants } from "core/mocks/app-constants";
import {
  enquiryRequest,
  enquiryTicketRequest,
} from "@models/request/enquiry-request";
import { BaseResponse } from "@models/response/base-response";

export const enquiryService = createApi({
  reducerPath: "enquiryService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.enquiryBaseUrl}`,
  }),
  tagTypes: ["Enquiry"],
  endpoints: (build) => ({
    makeEnquiry: build.mutation<BaseResponse<any>, enquiryRequest>({
      query: (body: enquiryRequest) => ({
        url: "/enquiry",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Enquiry"],
    }),
    getEnquiryList: build.query<
      BaseResponse<any>,
      { page_index: number; page_size: number }
    >({
      query: ({ page_index, page_size }) => ({
        url: `/enquiry?page_index=${page_index}&page_size=${page_size}`,
        method: "GET",
      }),
      providesTags: ["Enquiry"],
    }),
    replyEnquiryTicket: build.mutation<BaseResponse<any>, enquiryTicketRequest>(
      {
        query: (body: enquiryTicketRequest) => ({
          url: "/enquiry-ticket",
          method: "POST",
          body: body,
        }),
        invalidatesTags: ["Enquiry"],
      }
    ),
    getEnquiryTicketByEnquiryId: build.query<BaseResponse<any>, number>({
      query: (id) => ({
        url: `/enquiry-ticket/enquiry/${id}`,
        method: "GET",
      }),
      providesTags: ["Enquiry"],
    }),
  }),
});

export const {
  useMakeEnquiryMutation,
  useGetEnquiryListQuery,
  useReplyEnquiryTicketMutation,
  useGetEnquiryTicketByEnquiryIdQuery,
} = enquiryService;
