import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FormRequest } from "@models/request/form";
import { AppConstants } from "core/mocks/app-constants";
import { BaseResponse } from "@models/response/base-response";
import { FormResponse } from "@models/response/form-response";
import { Pagination } from "@models/pagination";

export const formService = createApi({
  reducerPath: "formService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.formBaseUrl}`,
  }),

  tagTypes: ["Forms"],
  endpoints: (build) => ({
    createForm: build.mutation<BaseResponse<FormResponse>, FormRequest>({
      query: () => ({
        url: `/form`,
        method: "POST",
      }),
      invalidatesTags: ["Forms"],
    }),

    getForm: build.query<BaseResponse<FormResponse>, number>({
      query: (id) => ({
        url: `/form/${id}`,
        method: "GET",
      }),
      providesTags: ["Forms"],
    }),
    getFormList: build.query<BaseResponse<FormResponse[]>, void>({
      query: (id) => ({
        url: `/form/list/mid?page=0&size=100`,
        method: "GET",
      }),
      providesTags: ["Forms"],
    }),
    updateForm: build.mutation<BaseResponse<FormResponse>, FormRequest>({
      query: (id) => ({
        url: `/form/`,
        method: "GET",
      }),
      invalidatesTags: ["Forms"],
    }),

  }),
});

export const {
  useCreateFormMutation,
  useGetFormQuery,
  useGetFormListQuery,
  useUpdateFormMutation,
} = formService;
