import {
  useGetExpectationsByPhoneNumberQuery,
  useCreateExpectationsMutation,
} from "services/event-service";
import React, { ChangeEvent, useState } from "react";
import { Row, Image, Form, Button } from "react-bootstrap";
import { FaStar, FaStarHalf } from "react-icons/fa6";
import { VscSend } from "react-icons/vsc";
import { reviewType } from "@models/request/create-event.request";
import toast from "react-hot-toast";

const UserExpectations = ({ EventId }) => {
  const [phoneNumber] = useState(sessionStorage.getItem("phoneNumber"));
  const [firstName] = useState(sessionStorage.getItem("firstName"));
  const [lastName] = useState(sessionStorage.getItem("lastName"));
  const { data: response, isLoading } = useGetExpectationsByPhoneNumberQuery({
    page: 0,
    size: 99999999,
    participantPhoneNumber: phoneNumber,
    event: EventId,
  });

  const Expectation = response?.data[0]?.data;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return { date: formattedDate, time: formattedTime };
  };

  const [expectationData, setExpectaionData] = useState<reviewType>({
    participantFirstName: firstName,
    participantLastName: lastName,
    participantPhoneNumber: phoneNumber,
    comment: "",
    eventId: EventId,
  });

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setExpectaionData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [expectationMutation] = useCreateExpectationsMutation();

  const handleExpectation = async (e) => {
    e.preventDefault();
    try {
      const response = await expectationMutation(expectationData);
      const { code, message } = response["data"];
      if (code === "00") {
        toast.success(message);
        setExpectaionData((prevData) => ({
          ...prevData,
          comment: "",
        }));
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating region:", error);
    }
  };

  return (
    <div className=" d-flex justify-content-between flex-column">
      <Row>
        <h1 className="full-details-event-title">
          Expectations <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStarHalf />
        </h1>

        {Expectation && Expectation.length > 0 ? (
          Expectation.map((expect) => (
            <Row className="pt-4" key={expect.id}>
              <div className="d-flex w-100 rounded-5 align-items-center">
                <Image
                  src={`https://ui-avatars.com/api/?name=${firstName}+${lastName}&background=random&size=32`}
                  alt="Avatar"
                  roundedCircle
                  className="rounded-circle my-auto"
                  width="40px"
                  height="40px"
                />
                <div className="ms-2">
                  <p
                    className="p-0 m-0"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {firstName} {lastName}{" "}
                    <span
                      style={{ fontSize: "10px", fontWeight: "500" }}
                      className="text-muted mx-1"
                    >
                      {formatDate(expect.createdOn).date}
                    </span>
                    <span
                      style={{ fontSize: "10px", fontWeight: "500" }}
                      className="text-muted mx-1"
                    >
                      {formatDate(expect.createdOn).time}
                    </span>
                  </p>
                  <p
                    className="lh-small m-0 p-0"
                    style={{ fontSize: "13px", fontWeight: "400" }}
                  >
                    {expect.comment}
                  </p>
                </div>
              </div>
            </Row>
          ))
        ) : (
          <Row className="pt-2">
            <p style={{ fontSize: "13px", fontWeight: "400" }}>
              No expectations available
            </p>
          </Row>
        )}
      </Row>
      <Row className="bg-white border mt-5 rounded-3">
        <Form.Group
          className="rounded-3 "
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Control
            className="rounded-3 border-0"
            as="textarea"
            name="comment"
            placeholder="Type message..."
            onChange={handleFormChange}
            value={expectationData.comment}
            rows={2}
          />
        </Form.Group>
        <span className="d-flex justify-content-end mb-2 mx-auto ">
          <Button
            className="p-0 bg-transparent border-0"
            onClick={handleExpectation}
            disabled={!expectationData.comment}
          >
            <VscSend className="rounded-circle bg-secondary p-2 text-white  fs-2" />
          </Button>
        </span>
      </Row>
    </div>
  );
};

export default UserExpectations;
