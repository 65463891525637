import { useEventCheckInMutation } from "services/event-service";
import React, { ChangeEvent, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { checkinType } from "models/request/create-event.request";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ForwardIcon } from "presentation/assets";

const AdminCheckIn = ({ eventId }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [checkInData, setCheckInData] = useState<checkinType>({
    participantFirstName: "",
    participantLastName: "",
    participantPhoneNumber: "",
    eventId: eventId,
  });

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setCheckInData((prevRegionData) => ({
      ...prevRegionData,
      [name]: value,
    }));
  };

  const [checkInMutation] = useEventCheckInMutation();

  const handleCheckIn = async (e) => {
    e.preventDefault();
    try {
      const response = await checkInMutation(checkInData);
      const { code, message } = response["data"];
      if (code === "00") {
        navigate(`/events/event-details/${eventId}`);
        toast.success(message);
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating region:", error);
    }
  };

  return (
    <>
      <Button
        onClick={handleShow}
        style={{ fontSize: "12px" }}
        variant="dark"
        className="rounded-5 event-button"
      >
        Check-In
        <img
          className="ms-2 d-inline-flex"
          src={ForwardIcon}
          alt=""
          width="14px"
        />
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "16px" }}>Check-In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "14px" }}>First Name</Form.Label>
              <Form.Control
                className="p-2"
                type="text"
                placeholder="Enter first name"
                autoFocus
                name="participantFirstName"
                onChange={handleFormChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "14px" }}>Last Name</Form.Label>
              <Form.Control
                className="p-2"
                type="text"
                placeholder="Enter last name"
                autoFocus
                name="participantLastName"
                onChange={handleFormChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "14px" }}>Phone Number</Form.Label>
              <Form.Control
                className="p-2"
                type="text"
                placeholder="Enter phone number"
                autoFocus
                name="participantPhoneNumber"
                onChange={handleFormChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ fontSize: "14px" }}
            variant="success"
            className="rounded-5"
            onClick={handleCheckIn}
          >
            Check-In
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminCheckIn;
