import React from "react";
import { Col, Container, Row} from "react-bootstrap";
import { Player } from "@lottiefiles/react-lottie-player";
import { NotFound } from "presentation/assets";

const NotFoundPage: React.FC = () => {
  return (
    <Container className="mx-auto vh-100 d-flex align-items-center justify-content-center">
      <Row>
        <Col>
          <Player src={NotFound} className="player w-75 h-75" loop autoplay />
        </Col>
      </Row>
    </Container>
  );
};

export default NotFoundPage;
