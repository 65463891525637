import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useGetUsersListQuery } from "services/user-service";
import Select from "react-select";
import { useCreateCounsellorMutation } from "services/counselling-service";
import { counsellorRequest } from "models/request/counselling-request";

const AddCounsellor = () => {
  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [counsellor, setCounsellor] = useState<counsellorRequest>({
    userId: 0,
    phone: "",
    firstName: "",
    lastName: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [createCounsellor] = useCreateCounsellorMutation();

  const { data: userResponse, isLoading } = useGetUsersListQuery({
    page_index: 0,
    page_size: 99999999,
  });

  const users = userResponse?.data;

  const UserOptions = users?.map((user) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName}`,
    phone: user.phone,
    firstName: user.firstName,
    lastName: user.lastName,
  }));

  const handleUserSelectChange = (selectedOption) => {
    setSelectedUser(selectedOption);
    setCounsellor({
      userId: selectedOption.value,
      phone: selectedOption.phone,
      firstName: selectedOption.firstName,
      lastName: selectedOption.lastName,
    });
  };

  const handleCreateCounsellor = async (e) => {
    e.preventDefault();
    try {
      const response = await createCounsellor(counsellor);
      const { code, message } = response["data"];
      if (code === 0) {
        toast.success(message);
        // Reset the form fields
        setSelectedUser(null);
        setCounsellor({
          userId: 0,
          phone: "",
          firstName: "",
          lastName: "",
        });
      } else {
        toast.error(message || "Something went wrong");
      }
      handleClose();
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating counsellor:", error);
    }
  };

  return (
    <>
      <Button
        className="py-2 mx-1 rounded-5 "
        variant="dark"
        style={{ fontSize: "14px" }}
        onClick={handleShow}
      >
        Add Counsellor +
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Add Counsellor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "14px" }}>User</Form.Label>
              <Select
                className="p-2"
                options={UserOptions}
                onChange={handleUserSelectChange}
                value={selectedUser}
                isLoading={isLoading}
                isDisabled={isLoading}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded-5"
            variant="secondary"
            onClick={handleClose}
            style={{ fontSize: "14px" }}
          >
            Close
          </Button>
          <Button
            style={{ fontSize: "14px" }}
            className="rounded-5"
            variant="primary"
            onClick={handleCreateCounsellor}
            disabled={!selectedUser}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddCounsellor;
