import { GridColDef } from "@mui/x-data-grid";
import RevokeRole from "../components/RevokeRole";

const ViewUserTable: GridColDef[] = [
  {
    field: "id",
    headerName: "Id",
    flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "username",
    headerName: "Username",
    flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "firstName",
    headerName: "First Name",
    flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "lastName",
    headerName: "Last Name",
    flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "actions",
    headerName: "Actions",
    headerClassName: "header--bg",
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      console.log("params", params);
      return (
        <div>
          <RevokeRole revoke={params?.row} />
        </div>
      );
    },
  },
];

export default ViewUserTable;
