import { useCancelCounsellMutation } from "services/counselling-service";
import React, { useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { cancelType } from "@models/request/counselling-request";

const CancelCounsellRequest = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const counselData = location.state?.selectedEvent;
  const [feedbackData, setFeedbackData] = useState<cancelType>({
    requestId: counselData?.id,
  });
  const [cancelCounsell] = useCancelCounsellMutation();

  const handleCancel = async () => {
    try {
      // Call the mutation hook to cancel the counseling session
      const response = await cancelCounsell(feedbackData);
      const { code, message } = response["data"];
      if (code === 0) {
        toast.success(message);
        navigate("/e-counselling/request");
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error canceling counseling session:", error);
      toast.error(error.message);
    }
  };

  return (
    <div>
      <div className="bg-enquiries">
        <Container className="vh-100 d-flex align-items-center justify-content-center">
          <Row className="justify-content-center">
            <Col md={12} xs={12}>
              <div className="py-13 pt-lg-20 pb-lg-20 text-center">
                <h1 className="display-3 fw-medium text-white mb-5">
                  Cancel counselling session
                </h1>
                <div
                  className="modal show rounded-1"
                  style={{ display: "block", position: "initial" }}
                >
                  <Col md={5} className="mx-auto">
                    <Card className="text-center">
                      <Card.Body>
                        <Card.Text className="my-3">
                          You are about to cancel your counselling session on
                          your {counselData.title}. Are you sure you want to
                          cancel your session?
                        </Card.Text>

                        <Row>
                          <Col className="text-center my-3">
                            <Button
                              variant="light"
                              className="rounded-5 me-2"
                              onClick={() => navigate(-1)}
                            >
                              Go back <RiArrowLeftLine />
                            </Button>
                            <Button
                              variant="dark"
                              type="submit"
                              className="rounded-5"
                              onClick={handleCancel}
                            >
                              Yes, cancel session
                              <RiArrowRightLine />
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CancelCounsellRequest;
