import ProtectedRoutes from "presentation/pages/auth/utils/ProtectedRoutes";
import React, { useState } from "react";
import { Col, Container, Form, Navbar, Row } from "react-bootstrap";
import { IoSearchOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import ViewUserDataTable from "./components/ViewUserDataTable";

const ViewUsersWithRoles = () => {
  const [selectedRoleName] = useState(
    sessionStorage.getItem("selectedRoleName")
  );
  const [searchQuery, setSearchQuery] = useState("");
  return (
    <Container fluid>
      <Navbar expand="lg" className="py-3 p-4 bg-transparent">
        <Navbar.Brand className="fs-4">
          Users with {selectedRoleName} Role
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="myNavbar2" className="navbar-toggler">
          <RxHamburgerMenu className="text-black fs-4" />
        </Navbar.Toggle>

        <Navbar.Collapse id="myNavbar2">
          <Row className="ms-auto flex-wrap align-items-center">
            <Col lg={7} className="mx-auto m-0 p-0 position-relative">
              <span className="position-absolute ps-2 pt-2 py-auto">
                <IoSearchOutline />
              </span>
              <Form.Control
                type="search"
                className="py-2 border border-1 rounded-5"
                style={{ paddingLeft: "1.8rem" }}
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Col>

            {/* <Col lg={5} className="mx-auto m-0 p-0">
              <CreateUserRole />
            </Col> */}
          </Row>
        </Navbar.Collapse>
      </Navbar>
      <Row>
        <Col>
          <ViewUserDataTable searchQuery={searchQuery} />
        </Col>
      </Row>
    </Container>
  );
};

export default ProtectedRoutes(ViewUsersWithRoles, {
  allowedRoles: ["Super Admin"],
});
