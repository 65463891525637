import { enquiryRequest } from "models/request/enquiry-request";
import { useMakeEnquiryMutation } from "services/enquiry-service";
import { ChangeEvent, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import { RiArrowRightLine } from "react-icons/ri";
import { NavLink, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";

const MakeEnquiriesPage = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [enquiryData, setEnquiryData] = useState<enquiryRequest>({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
    requestChannel: "WEB",
  });

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEnquiryData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [makeEnquiry] = useMakeEnquiryMutation();

  const handleFormSubmit = async (e) => {
    try {
      const response = await makeEnquiry(enquiryData);
      const { code, message, data } = response["data"];
      if (code === 200) {
        toast.success(message);
        navigate("/enquiries/message-sent", { state: { data } });
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };

  return (
    <Container fluid className="bg-enquiries">
      <Row className="min-vh-100 align-items-center">
        {/* <Col sm={{ span: 6, offset: 3 }}> */}
        <Col lg={7} className="mx-auto">
          <h4 className="text-center mt-4 fs-5 fw-normal mb-4">
            Got any enquiry? Send it our way.
          </h4>

          <Card className="border-0 rounded-4">
            <Card.Body className="p-5 ">
              <Form>
                <FloatingLabel
                  className="mb-1"
                  controlId="floatingName"
                  label="Name"
                >
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleFormChanged}
                    placeholder="Enter your name"
                  />
                </FloatingLabel>

                <FloatingLabel
                  className="mb-1"
                  controlId="floatingEmail"
                  label="Email"
                >
                  <Form.Control
                    type="email"
                    {...register("email", {
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Email format is invalid",
                      },
                    })}
                    onChange={handleFormChanged}
                    placeholder="Email"
                  />
                  {errors.email && (
                    <Form.Text className="text-danger">
                      {String(errors.email.message)}
                    </Form.Text>
                  )}
                </FloatingLabel>

                <FloatingLabel
                  className="mb-1"
                  controlId="floatingPhone"
                  label="Phone"
                >
                  <Form.Control
                    type="number"
                    name="phoneNumber"
                    onChange={handleFormChanged}
                    placeholder="02xxxxxxxx, 05xxxxxxxx"
                  />
                </FloatingLabel>

                <FloatingLabel
                  controlId="floatingMessage"
                  label="Enter message"
                >
                  <Form.Control
                    as="textarea"
                    name="message"
                    placeholder="Enter message"
                    onChange={handleFormChanged}
                    style={{ height: "100px" }}
                  />
                </FloatingLabel>

                <Row>
                  <Col className="text-center mt-5 mb-3">
                    <Button
                      variant="light"
                      className="rounded-5 me-2"
                      onClick={() => navigate(-1)}
                    >
                      Go back
                    </Button>
                    <Button
                      variant="dark"
                      type="submit"
                      onClick={handleSubmit(handleFormSubmit)}
                      className="rounded-5"
                      disabled={
                        !enquiryData.name ||
                        !enquiryData.email ||
                        !enquiryData.message
                      }
                    >
                      Send message <RiArrowRightLine />
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
          <p className="text-center text-muted mt-3 fw-light">
            Click here to <NavLink to={"/auth/register"}>Sign up</NavLink> or{" "}
            <NavLink to={"/auth/login"}>Sign in</NavLink>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default MakeEnquiriesPage;
