import { useGetEventPartnersListQuery } from "services/event-service";
import Select from "react-select";
import { OnchangeProps } from "models/request/create-event.request";

interface CollaboratorsType {
  id: number;
  name: string;
}

interface CollaboratorProps extends OnchangeProps {
  collaboratorsValue?: number[];
}

const Collaborators: React.FC<CollaboratorProps> = ({ name, onChange, collaboratorsValue = [] }) => {
  const { data: response } = useGetEventPartnersListQuery({
    page: 0,
    size: 99999999,
  });

  const users: CollaboratorsType[] = (response?.data[0]?.data ?? []) as CollaboratorsType[];

  const collaboratorsOptions: { value: number; label: string }[] = users.map(
    (user) => ({
      value: user.id,
      label: user.name,
    })
  );

  return (
    <div style={{ fontSize: "14px" }}>
      <Select
        className="basic-multi-select"
        classNamePrefix="select"
        options={collaboratorsOptions}
        isMulti
        name={name}
        onChange={onChange}
        value={collaboratorsOptions.filter(option => collaboratorsValue.includes(option.value))}
      />
    </div>
  );
};

export default Collaborators;
