import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Image,
  Badge,
  ListGroup,
} from "react-bootstrap";

const ViewCounsellorDetails = ({ details }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        variant="light"
        className="rounded-5 "
        style={{ fontSize: "12px", background: "rgba(25, 22, 29, 0.06)" }}
        onClick={handleShow}
      >
        View Slots
      </Button>
      <Modal show={show} onHide={handleClose}>
        <ListGroup as="ol" numbered>
          {details?.availabilitySlots?.length === 0 ? (
            <ListGroup.Item>No available slots</ListGroup.Item>
          ) : (
            details?.availabilitySlots?.map((slot) => (
              <ListGroup.Item
                key={slot.id}
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div><span className="fw-bold">Time:</span>{slot.time.value} {slot.time.unit}</div>
                  <p>
                    <span className="fw-bold">Start Time:</span>{" "}
                    {slot.startTime}{"  "} <span className="fw-bold">End Time:</span>{" "}
                    {slot.endTime}
                  </p>
                </div>
              </ListGroup.Item>
            ))
          )}
        </ListGroup>
      </Modal>
    </>
  );
};

export default ViewCounsellorDetails;
