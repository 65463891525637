import React from "react";
import { LuUsers2 } from "react-icons/lu";
import { FaRegHandshake, FaUsers, FaUserTie } from "react-icons/fa6";
import { IoChatbubbles, IoSettingsOutline } from "react-icons/io5";
import { Image } from "react-bootstrap";
import {
  DashboardIcon,
  Ecounselling,
  Elearning,
  Ementoring,
  EventIcon,
} from "../../presentation/assets";
import { PiLightbulbFilamentLight } from "react-icons/pi";
import { MdMarkUnreadChatAlt, MdOutlinePermMedia } from "react-icons/md";
import { TbMessageChatbot } from "react-icons/tb";

export class SidebarMenus {
  static profileActions = [
    {
      name: "Settings",
      icon: <IoSettingsOutline />,
      url: "/settings",
    },
    {
      name: "Logout",
      icon: <FaUsers />,
      url: "/logout",
    },
  ];

  static firstMenus = [
    {
      name: "Dashboard",
      icon: <Image src={DashboardIcon} alt="" />,
      url: "/dashboard",
    },
    {
      name: "Events",
      icon: <Image src={EventIcon} alt="" />,
      url: "/events",
    },
    {
      name: "E-learning",
      icon: <Image src={Elearning} alt="" />,
      url: "https://viennabackend.nsano.com/lms/login/index.php",
    },
    {
      name: "E-counselling",
      icon: <Image src={Ecounselling} alt="" />,
      url: "/e-counselling/requests",
    },
    {
      name: "Users",
      icon: <LuUsers2 />,
      url: "/users",
    },
  ];

  static eventMenus = [
    {
      name: "Events",
      icon: <Image src={EventIcon} alt="" />,
      url: "/events",
    },
    // {
    //   name: "Regions",
    //   icon: <TfiMapAlt />,
    //   url: "/events/regions",
    // },
    // {
    //   name: "Districts",
    //   icon: <RiMapPinUserLine />,
    //   url: "/events/districts",
    // },
    {
      name: "Media Houses",
      icon: <MdOutlinePermMedia />,
      url: "/events/media-houses",
    },
    {
      name: "Partners",
      icon: <FaRegHandshake />,
      url: "/events/partners",
    },
  ];

  static userMenus = [
    {
      name: "Users",
      icon: <LuUsers2 />,
      url: "/users",
    },
    {
      name: "User Roles",
      icon: <FaUserTie />,
      url: "/users/roles",
    },
  ];

  static counsellingMenus = [
    {
      name: "Counsel Request",
      icon: <MdMarkUnreadChatAlt />,
      url: "/e-counselling/request",
    },
    {
      name: "Counselling List",
      icon: <IoChatbubbles />,
      url: "/e-counselling/list",
    },
    {
      name: "Counsellor",
      icon: <PiLightbulbFilamentLight />,
      url: "/e-counselling/counsellor",
    },
  ];

  static secondMenus = [
    {
      name: "E-mentoring",
      icon: <img src={Ementoring} alt="" />,
      url: "/e-mentoring/groups",
    },
    {
      name: "Enquiries",
      icon: <TbMessageChatbot className="fs-6" />,
      url: "/enquiries/list",
    },
  ];
  static mentoringMenus = [
    {
      name: "Groups",
      icon: <MdMarkUnreadChatAlt />,
      url: "/e-mentoring/groups",
    },
    {
      name: "List",
      icon: <IoChatbubbles />,
      url: "/e-mentoring/list",
    },
   
  ];
}
