import { GridColDef } from "@mui/x-data-grid";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import DeleteRole from "../components/DeleteRole";

const UsersRoleTable: GridColDef[] = [
  // {
  //   field: "id",
  //   headerName: "Id",
  //   flex: 1,
  //   headerClassName: "header--bg",
  // },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "actions",
    headerName: "Actions",
    headerClassName: "header--bg",
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => (
      <div>
        <Link to={`/user/view-users-roles/${params?.row?.name}`}>
          <Button
            variant="light"
            style={{ fontSize: "12px" }}
            className="rounded-5"
            onClick={() => {
              // Store params.row.name in sessionStorage
              sessionStorage.setItem("selectedRoleName", params?.row?.name);
            }}
          >
            View Users
          </Button>
        </Link>
        <DeleteRole rolename={params?.row?.name} />
      </div>
    ),
  },
];

export default UsersRoleTable;
