import React from "react";
import { CiSearch } from "react-icons/ci";
import { Card, Col, Nav, Row } from "react-bootstrap";
import { SettingSidebarData } from "./SettingSidebarData";
import { NavLink } from "react-router-dom";

const SettingSidebar = () => {
  const { PersonalSettings, GeneralSettings } = SettingSidebarData;
  return (
    <Row>
      <Col>
        <Card className="shadow-none d-none d-sm-inline border-0 rounded-3 mb-3" style={{height: "90vh"}}>
          <div className="p-3">
            <div className="mb-3">
              <div>
                <span className="position-absolute ps-2 pt-1 py-auto">
                  <CiSearch />
                </span>
                <input
                  type="search"
                  className="w-100 ps-4 py-1 mb-2 rounded-5"
                  placeholder="Search"
                />
              </div>
            </div>
            <Nav defaultActiveKey="/account" className="flex-column mt-2">
              <div className="px-2 mt-2">
                <h5 className="pb-2 settings-title">Personal Settings</h5>

                {PersonalSettings.map((menuItem, index) => (
                  <Nav.Link
                    key={index}
                    as={NavLink}
                    to={menuItem.url}
                    className="mb-2 p-0 text-black"
                  >
                    {menuItem.icon}
                    <span className="ms-1 px-2 d-none d-sm-inline settings-text">
                      {menuItem.name}
                    </span>
                  </Nav.Link>
                ))}
              </div>
              <div className="px-2 mt-2">
                <h5 className="pb-2 settings-title">General Settings</h5>
                {GeneralSettings.map((menuItem, index) => (
                  <Nav.Link
                    key={index}
                    as={NavLink}
                    to={menuItem.url}
                    className="mb-2 p-0 text-black"
                  >
                    {menuItem.icon}
                    <span className="ms-1 px-2 d-none d-sm-inline settings-text">
                      {menuItem.name}
                    </span>
                  </Nav.Link>
                ))}
              </div>
            </Nav>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default SettingSidebar;
