import React, { useState } from "react";
import { Button, Card, Col, Modal, Row, Image, Badge } from "react-bootstrap";

const ViewDetails = ({ request }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        variant="light"
        className="rounded-5 "
        style={{ fontSize: "12px", background: "rgba(25, 22, 29, 0.06)" }}
        onClick={handleShow}
      >
        View details ...
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Card>
          <Card.Body className="p-5">
            <div className="text-center">
              <h4 className="mb-0 fw-semibold">
                {request?.confirmation?.assignMentorShipGroup?.name}
              </h4>
              <p className="mb-0">
                {request?.confirmation?.assignMentorShipGroup?.description}
              </p>
            </div>
            <Row style={{ fontSize: "12px" }} className="">
              <div className="d-flex justify-content-between border-bottom py-2 mt-4">
                <span>Requested by</span>
                <span className="text-dark">
                  <Image
                    src={`https://ui-avatars.com/api/?name=${request?.requestedBy[0]?.firstName}+${request?.requestedBy[0].lastName}&background=random&size=32`}
                    alt="Avatar"
                    roundedCircle
                    className="me-2"
                  />
                  {request?.requestedBy[0]?.firstName}{" "}
                  {request?.requestedBy[0]?.lastName}
                </span>
              </div>
              <div className="d-flex  justify-content-between border-bottom py-2">
                <span>Preferred Start Date</span>
                <span className="">
                  {request?.preferredStartAt
                    ? new Date(request?.preferredStartAt).toLocaleDateString()
                    : ""}{" "}
                  -{" "}
                  {request?.preferredStartAt
                    ? new Date(request?.preferredStartAt).toLocaleTimeString()
                    : ""}
                </span>
              </div>
              <div className="d-flex justify-content-between border-bottom py-2">
                <span>Preferred End Date</span>

                <span className="">
                  {request?.preferredEndAt
                    ? new Date(request?.preferredEndAt).toLocaleDateString()
                    : ""}{" "}
                  -{" "}
                  {request?.preferredEndAt
                    ? new Date(request?.preferredEndAt).toLocaleTimeString()
                    : ""}
                </span>
              </div>
              <div className="d-flex justify-content-between border-bottom py-2">
                <span>Counsellors</span>
                <span className="text-dark">
                  {" "}
                  {request?.confirmation?.assignMentorShipGroup.mentors.map(
                    (mentor: any) => (
                      <div key={mentor.id}>
                        <Image
                          src={`https://ui-avatars.com/api/?name=${mentor.user.firstName}+${mentor.user.lastName}&background=random&size=32`}
                          alt="Avatar"
                          roundedCircle
                          className="me-2"
                        />
                        {mentor.user.firstName} {mentor.user.lastName}
                      </div>
                    )
                  )}
                </span>
              </div>
              <div className="d-flex justify-content-between border-bottom py-2">
                <span>Members</span>
                <span className="text-dark">
                  {" "}
                  {request?.confirmation?.assignMentorShipGroup.members.map(
                    (member: any) => (
                      <div key={member.id}>
                        <Image
                          src={`https://ui-avatars.com/api/?name=${member.firstName}+${member.lastName}&background=random&size=32`}
                          alt="Avatar"
                          roundedCircle
                          className="me-2"
                        />
                        {member.firstName} {member.lastName}
                      </div>
                    )
                  )}
                </span>
              </div>
              <div className="d-flex  justify-content-between border-bottom py-2">
                <span>Start Date</span>
                <span className="">
                  {request?.confirmation?.startAt
                    ? new Date(
                        request?.confirmation?.startAt
                      ).toLocaleDateString()
                    : ""}{" "}
                  -{" "}
                  {request?.confirmation?.startAt
                    ? new Date(
                        request?.confirmation?.startAt
                      ).toLocaleTimeString()
                    : ""}
                </span>
              </div>
              <div className="d-flex justify-content-between border-bottom py-2">
                <span> End Date</span>

                <span className="">
                  {request?.confirmation?.endAt
                    ? new Date(
                        request?.confirmation?.endAt
                      ).toLocaleDateString()
                    : ""}{" "}
                  -{" "}
                  {request?.confirmation?.endAt
                    ? new Date(
                        request?.confirmation?.endAt
                      ).toLocaleTimeString()
                    : ""}
                </span>
              </div>
              <div className="d-flex justify-content-between border-bottom py-2">
                <span> Date Created</span>

                <span className="">
                  {request?.createdOn
                    ? new Date(request?.createdOn).toLocaleDateString()
                    : ""}{" "}
                </span>
              </div>
            </Row>
          </Card.Body>
        </Card>
      </Modal>
    </>
  );
};

export default ViewDetails;
