import React, { useState } from "react";
import { Breadcrumb, Button, Navbar } from "react-bootstrap";
import { CiEdit } from "react-icons/ci";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import EventParticipate from "../../eventMain/components/EventParticipate";
import UnregisterEvent from "../../eventMain/components/UnregisterEvent";
import { RxHamburgerMenu } from "react-icons/rx";

const FullDetailsEventHeader = ({
  eventtitle,
  eventId,
  eventParticipantsWithUserId,
  eventstatus,
}) => {
  const roles = sessionStorage.getItem("roles");
  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);
  const allowedRoles = ["Admin", "Super Admin", "Comms (Communications)"];
  const hasAllowedRole =
    roles && allowedRoles.some((role) => roles.includes(role));

  return (
    <div>
      <Navbar expand="lg" className="p-4 bg-black">
        <div className="d-flex flex-column">
          <Navbar.Brand className="text-white py-3 create-event-title">
            {eventtitle}
          </Navbar.Brand>
          <Breadcrumb>
            <Breadcrumb.Item
              className=" text-white text-decoration-none"
              as={Link}
              to="/events"
              style={{ fontSize: "12px" }}
              active
            >
              Events
            </Breadcrumb.Item>
            <Breadcrumb.Item
              style={{ fontSize: "12px" }}
              active
              className="text-white"
            >
              {eventtitle}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Navbar.Toggle aria-controls="myNavbar2" className="navbar-toggler">
          <RxHamburgerMenu className="text-white fs-4" />
        </Navbar.Toggle>

        <Navbar.Collapse id="myNavbar2">
          <div className="d-flex ms-auto flex-wrap justify-content-start">
            {hasAllowedRole ? (
              <>
                {eventstatus === "UPCOMING" && (
                  <Link to={`/events/edit-event/${eventId}`}>
                    <Button className="ms-2" variant="dark rounded-5">
                      Edit event <CiEdit />
                    </Button>
                  </Link>
                )}
              </>
            ) : eventParticipantsWithUserId.includes(userId) ? (
              <UnregisterEvent eventId={eventId} />
            ) : (
              <EventParticipate eventId={eventId} />
            )}
            <Link to="/events">
              <Button
                className="ms-2"
                variant="dark rounded-5"
                style={{ fontSize: "14px", fontWeight: "400" }}
              >
                Go back to events <IoArrowBackOutline />
              </Button>
            </Link>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default FullDetailsEventHeader;
