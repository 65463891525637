import React, { useState } from "react";
import { Row, Image } from "react-bootstrap";
import { FaStar, FaStarHalf } from "react-icons/fa6";

const AdminExpectaions = ({ EventExpectation }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return { date: formattedDate, time: formattedTime };
  };

  return (
    <div
      className=" d-flex justify-content-between flex-column"
      style={{ height: "70vh" }}
    >
      <Row>
        <h1 className="full-details-event-title">
          Expectations <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStarHalf />
        </h1>

        {EventExpectation && EventExpectation.length > 0 ? (
          EventExpectation.map((expect) => (
            <Row className="pt-4" key={expect.id}>
              <div className="d-flex w-100 rounded-5 align-items-center">
                <Image
                  src={`https://ui-avatars.com/api/?name=${expect.participantFirstName}+${expect.participantLastName}&background=random&size=32`}
                  alt="Avatar"
                  roundedCircle
                  className="rounded-circle my-auto"
                  width="40px"
                  height="40px"
                />
                <div className="ms-2">
                  <p
                    className="p-0 m-0"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {expect.participantFirstName} {expect.participantLastName}
                    <span
                      style={{ fontSize: "10px", fontWeight: "500" }}
                      className="text-muted mx-1"
                    >
                      {formatDate(expect.createdOn).date}
                    </span>
                    <span
                      style={{ fontSize: "10px", fontWeight: "500" }}
                      className="text-muted mx-1"
                    >
                      {formatDate(expect.createdOn).time}
                    </span>
                  </p>
                  <p
                    className="lh-small m-0 p-0"
                    style={{ fontSize: "13px", fontWeight: "400" }}
                  >
                    {expect.comment}
                  </p>
                </div>
              </div>
            </Row>
          ))
        ) : (
          <Row className="pt-2">
            <p style={{ fontSize: "13px", fontWeight: "400" }}>
              No Expectations available
            </p>
          </Row>
        )}
      </Row>
    </div>
  );
};

export default AdminExpectaions;
