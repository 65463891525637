import { GridColDef } from "@mui/x-data-grid";
import { Image } from "react-bootstrap";
import UserModal from "../components/UserModal";
import DeleteUser from "../components/DeleteUser";
import EditUserModal from "../components/EditUserModal";

const roles = sessionStorage.getItem("roles");
const allowedRoles = ["Admin", "Super Admin"];
const hasAllowedRole =
  roles && allowedRoles.some((role) => roles.includes(role));

const UsersTable: GridColDef[] = [
  {
    field: "fullName",
    headerName: "Full Name",
    headerClassName: "header--bg",
    flex: 1,
    renderCell: (params: any) => (
      <>
        <Image
          src={`https://ui-avatars.com/api/?name=${params?.row?.firstName}+${params?.row?.lastName}&background=random&size=32`}
          alt="Avatar"
          roundedCircle
          className="me-2"
        />
        {params?.row?.firstName || ""} {params?.row?.lastName || ""}
      </>
    ),
  },
  {
    field: "phone",
    headerName: "Phone Number",
    flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "gender",
    headerName: "Gender",
    headerClassName: "header--bg",
    valueGetter: (params: any) =>
      params?.row?.gender === "M" ? "Male" : "Female",
  },
  {
    field: "springboardCategory",
    headerName: "Category",
    flex: 1,
    headerClassName: "header--bg",
    valueGetter: (params: any) =>
      params?.value ? (params?.value as string[]).join(", ") : "",
  },
  {
    field: "registrationChannel",
    headerName: "Registration Channel",
    flex: 1,
    headerClassName: "header--bg",
  },
  {
    field: "createdOn",
    headerName: "Date Created",
    flex: 1,
    headerClassName: "header--bg",
    valueFormatter: (params: any) =>
      new Date(params?.value).toLocaleDateString(),
  },
  {
    field: "actions",
    headerName: "Actions",
    headerClassName: "header--bg",
    width: 200,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => (
      <div>
        {hasAllowedRole && <EditUserModal userData={params?.row} />}
        <UserModal userId={params?.row?.id} userData={params?.row} />
        <DeleteUser userId={params?.row?.id} />
      </div>
    ),
  },
];

export default UsersTable;
