import React, { useState } from "react";
import { Image, Modal, ListGroup } from "react-bootstrap";
import { GrFormView } from "react-icons/gr";
import RemoveMember from "./removeMember/RemoveMember";

const ViewMembers = ({ groupMembers, groupId }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div
        className="py-1"
        style={{ fontSize: "14px", cursor: "pointer" }}
        onClick={handleShow}
      >
        View Members <GrFormView />
      </div>
      <Modal  show={show} onHide={handleClose}>
        <Modal.Title className="fs-6 p-3" >All Members </Modal.Title>
        <Modal.Body className="p-3">
          <ListGroup as="ol" numbered>
            {groupMembers?.length === 0 ? (
              <ListGroup.Item>No Members</ListGroup.Item>
            ) : (
              groupMembers?.map((member) => (
                <ListGroup.Item
                  key={member.id}
                  as="li"
                  className="d-flex justify-content-between align-items-start"
                >
                  <div>
                    <Image
                      src={`https://ui-avatars.com/api/?name=${member.firstName}+${member.lastName}&background=random&size=32`}
                      alt="Avatar"
                      roundedCircle
                      className="me-2"
                    />
                    {member.firstName || ""} {member.lastName || ""}
                  </div>
                  <div>
                    <RemoveMember member={member} groupID={groupId} />
                  </div>
                </ListGroup.Item>
              ))
            )}
          </ListGroup>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewMembers;
