import { Col, Row } from "react-bootstrap";
import { DateRange } from "react-date-range";

const DateCalender = ({ date, onDateChange }) => {
  return (
    <Row>
      <Col xs={12} className="bg-white mt-5 event-details-calender">
        <p style={{ fontSize: "12px" }}>
          Start date - End date
        </p>
        <DateRange
          editableDateInputs={true}
          onChange={onDateChange}
          moveRangeOnFirstSelection={false}
          ranges={date}
        />
      </Col>
    </Row>
  );
};

export default DateCalender;
