import { useUpdateUserByIDMutation } from "services/user-service";
import { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { editUserType } from "@models/request/create-user.request";

const EditUserModal = ({ userData }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const springboardCategory = userData?.springboardCategory;
  console.log("springboardCategory123", springboardCategory);

  const [editUserData, setEditUserData] = useState<editUserType>({
    id: userData?.id,
    firstName: userData?.firstName,
    middleName: userData?.middleName,
    lastName: userData?.lastName,
    email: userData?.email,
    phone: userData?.phone,
    whatsappNumber: userData?.whatsappNumber,
    gender: userData?.gender,
    age: userData?.age,
    highestLevelOfEducation: userData?.highestLevelOfEducation,
    springboardCategory: Array.isArray(userData?.springboardCategory)
      ? userData.springboardCategory
      : [],
    disabilityStatus: Array.isArray(userData?.disabilityStatus)
      ? userData.disabilityStatus
      : [],
  });

  console.log("editUserData", editUserData);

  const springboardCategoryOptions = [
    { value: "Employer", label: "Employer" },
    { value: "Job Seeker(Student)", label: "Job Seeker(Student)" },
    { value: "Job Seeker(Unemployed)", label: "Job Seeker(Unemployed)" },
    { value: "Job Seeker(Employee)", label: "Job Seeker(Employee)" },
    {
      value: "Support Partner(Volunteer)",
      label: "Support Partner(Volunteer)",
    },
    { value: "Support Partner(Vendor)", label: "Support Partner(Vendor)" },
    {
      value: "Support Partner(Facilitator)",
      label: "Support Partner(Facilitator)",
    },
    {
      value: "Stakeholder",
      label: "Stakeholder",
    },
    {
      value: "Association, Group Or Partner",
      label: "Association, Group Or Partner",
    },
  ];

  const disabiltyOptions = [
    { value: "None", label: "None" },
    { value: "Blind", label: "Blind" },
    { value: "Deaf", label: "Deaf" },
    { value: "Physically Challenged", label: "Physically Challenged" },
    { value: "Speech Impaired", label: "Speech Impaired" },
    { value: "Albino", label: "Albino" },
  ];

  const handleSpringboardCategoryChange = (selectedOptions) => {
    setEditUserData((prevUserData) => ({
      ...prevUserData,
      springboardCategory: selectedOptions.map((option) => option.value),
    }));
  };

  const handledDisabilityStatusChange = (selectedOptions) => {
    setEditUserData((prevUserData) => ({
      ...prevUserData,
      disabilityStatus: selectedOptions.map((option) => option.value),
    }));
  };

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const handleFormSelectChanged: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    const { name, value } = e.target;
    setEditUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const [editUser] = useUpdateUserByIDMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const missingFields = [];
    if (editUserData.springboardCategory.length === 0)
      missingFields.push("Springboard Category");
    if (editUserData.disabilityStatus.length === 0)
      missingFields.push("Disability Status");

    if (missingFields.length > 0) {
      const errorMessage = `${missingFields.join(", ")} must not be empty`;
      toast.error(errorMessage);
      return;
    }

    try {
      const response = await editUser(editUserData);
      const { code, message } = response["data"];
      console.log("response", response);
      if (code === 200) {
        toast.success(message);

        const finalResponse = {
          springboardCategory: editUserData.springboardCategory,
        };
        navigate(`/auth/signup-registration-2`, { state: finalResponse });
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    setEditUserData((prev) => ({
      ...prev,
      id: userData?.id,
      firstName: userData?.firstName,
      middleName: userData?.middleName,
      lastName: userData?.lastName,
      email: userData?.email,
      phone: userData?.phone,
      whatsappNumber: userData?.whatsappNumber,
      gender: userData?.gender,
      age: userData?.age,
      highestLevelOfEducation: userData?.highestLevelOfEducation,
      springboardCategory: Array.isArray(userData?.springboardCategory)
        ? userData.springboardCategory
        : [],
      disabilityStatus: Array.isArray(userData?.disabilityStatus)
        ? userData.disabilityStatus
        : [],
    }));
  }, [userData]);

  return (
    <>
      <Button
        className="rounded-5 text-black border-0"
        style={{ fontSize: "12px", background: "rgba(25, 22, 29, 0.06)" }}
        onClick={handleShow}
      >
        edit
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Edit User</Modal.Title>
        </Modal.Header>
        <form className="g-3 ">
          <Modal.Body className="m-0 p-0">
            <Card className="border-0  rounded-3">
              <Card.Body className=" p-4">
                <Col className="">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="First Name"
                    className="my-2 settings-text"
                  >
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={editUserData.firstName}
                      onChange={handleFormChanged}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Middle Name"
                    className="my-2 settings-text"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Middle Name"
                      value={editUserData.middleName}
                      name="middleName"
                      onChange={handleFormChanged}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Last Name"
                    className="my-2 settings-text"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={editUserData.lastName}
                      onChange={handleFormChanged}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="E-mail"
                    className="my-2 settings-text"
                  >
                    <Form.Control
                      type="email"
                      placeholder="E-mail"
                      name="email"
                      value={editUserData.email}
                      onChange={handleFormChanged}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Phone"
                    className="my-2 settings-text"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Phone"
                      name="phone"
                      value={editUserData.phone}
                      onChange={handleFormChanged}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Whatsapp Number"
                    className="my-2 settings-text"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Whatsapp Number"
                      name="whatsappNumber"
                      value={editUserData.whatsappNumber}
                      onChange={handleFormChanged}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    className="mb-2 settings-text"
                    controlId="floatingSelect"
                    label="Sex"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      value={editUserData.gender}
                      name="gender"
                      onChange={handleFormSelectChanged}
                    >
                      <option selected disabled>
                        Sex
                      </option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </Form.Select>
                  </FloatingLabel>
                  <FloatingLabel
                    className="mb-2 settings-text"
                    controlId="floatingSelect"
                    label="Age"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      value={editUserData.age}
                      name="age"
                      onChange={handleFormSelectChanged}
                    >
                      <option selected disabled>
                        Age
                      </option>
                      <option value="AGE_BELOW_15">Below 15yrs</option>
                      <option value="AGE_15_17">15 – 17 yrs</option>
                      <option value="AGE_18_24">18 – 24 yrs</option>
                      <option value="AGE_25_30">25 – 30 yrs </option>
                      <option value="AGE_31_35">31 – 35 years </option>
                      <option value="AGE_36_PLUS">36+ years </option>
                    </Form.Select>
                  </FloatingLabel>
                  <FloatingLabel
                    className="mb-2 settings-text"
                    controlId="floatingSelect"
                    label="highestLevelOfEducation"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      value={editUserData.highestLevelOfEducation}
                      name="highestLevelOfEducation"
                      onChange={handleFormSelectChanged}
                    >
                      <option value="" disabled>
                        Highest Level of Eduction
                      </option>
                      <option value="No Formal Education">
                        No Formal Education
                      </option>
                      <option value="Primary">Primary</option>
                      <option value="Junior High School (JHS)">
                        Junior High School (JHS)
                      </option>
                      <option value="Senior High School (SHS)">
                        Senior High School (SHS)
                      </option>
                      <option value="TVET (Secondary)">TVET (Secondary)</option>
                      <option value="TVET (Tertiary)">TVET (Tertiary)</option>
                      <option value="First Degree / Bachelors">
                        First Degree / Bachelors
                      </option>
                      <option value="Master’s Degree">Master’s Degree</option>
                      <option value="Doctorate Degree">Doctorate Degree</option>
                    </Form.Select>
                  </FloatingLabel>
                  <Form.Group>
                    <Select
                      className="basic-multi-select mb-2"
                      classNamePrefix="select"
                      options={springboardCategoryOptions}
                      isMulti
                      name="springboardCategory"
                      placeholder="Select Springboard Category"
                      value={
                        editUserData.springboardCategory
                          ? springboardCategoryOptions.filter((option) =>
                              editUserData.springboardCategory.includes(
                                option.value
                              )
                            )
                          : []
                      }
                      onChange={handleSpringboardCategoryChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Select
                      className="basic-multi-select mb-2"
                      classNamePrefix="select"
                      options={disabiltyOptions}
                      isMulti
                      name="disabilityStatus"
                      placeholder="Select Disability Status"
                      value={
                        editUserData.disabilityStatus
                          ? disabiltyOptions.filter((option) =>
                              editUserData.disabilityStatus.includes(
                                option.value
                              )
                            )
                          : []
                      }
                      onChange={handledDisabilityStatusChange}
                    />
                  </Form.Group>
                </Col>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="dark"
              className="users-text rounded-5"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              onClick={handleFormSubmit}
              type="submit"
              className="users-text rounded-5"
              variant="success"
            >
              Update User
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default EditUserModal;
