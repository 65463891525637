import {
  useCreateDistrictMutation,
  useGetEventRegionListQuery,
} from "services/event-service";
import React, { ChangeEvent, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { districtType } from "models/request/create-event.request";
import toast from "react-hot-toast";

const CreateDistrictModal = () => {
  const [show, setShow] = useState(false);
  const [districtData, setDistrictData] = useState<districtType>({
    regionId: 0,
    name: "",
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setDistrictData((prevRegionData) => ({
      ...prevRegionData,
      [name]: value,
    }));
  };

  const handleFormSelectChanged: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    const { name, value } = e.target;
    setDistrictData((prevEventData) => ({
      ...prevEventData,
      [name]: value,
    }));
  };

  const { data: response, isLoading } = useGetEventRegionListQuery({
    page: 0,
    size: 99999999,
  });

  const regionResponse = response?.data[0]?.data;

  const RegionOptions: { value: number; label: string }[] = regionResponse?.map(
    (region) => ({
      value: region.id,
      label: region.name,
    })
  );

  const [createDistrictMutation] = useCreateDistrictMutation();

  const handleCreateRegion = async (e) => {
    e.preventDefault();
    if (!districtData.name.trim()) {
      toast.error("Region must not be empty");
    }
    try {
      const response = await createDistrictMutation(districtData);
      const { code, message } = response["data"];
      if (code === "00") {
        toast.success(message);
      } else {
        toast.error(message || "Something went wrong");
      }
      handleClose();
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating region:", error);
    }
  };

  return (
    <>
      <Button
        className="py-2 rounded-5 mx-auto"
        variant="dark"
        style={{ fontSize: "14px" }}
        onClick={handleShow}
      >
        Create district +
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Create a district</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label style={{ fontSize: "14px" }}>Regions</Form.Label>
            <Form.Select
              className="p-2"
              aria-label="Default select example"
              name="regionId"
              onChange={handleFormSelectChanged}
            >
              <option className="text-muted" value="" selected disabled>
                Select region
              </option>
              {RegionOptions?.map((region) => (
                <option key={region.value} value={region.value}>
                  {region.label}
                </option>
              ))}
            </Form.Select>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "14px" }}>District</Form.Label>
              <Form.Control
                className="p-2"
                type="text"
                placeholder="Enter district name"
                autoFocus
                name="name"
                onChange={handleFormChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded-5"
            variant="secondary"
            onClick={handleClose}
            style={{ fontSize: "14px" }}
          >
            Close
          </Button>
          <Button
            style={{ fontSize: "14px" }}
            className="rounded-5"
            variant="primary"
            onClick={handleCreateRegion} // Call handleCreateRegion on button click
          >
            Create district
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateDistrictModal;
