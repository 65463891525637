import React from "react";
import { Navbar, Button, Breadcrumb } from "react-bootstrap";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";

const CreateEventHeader: React.FC = () => {
  return (
    <div>
      <Navbar expand="lg" className="p-4 bg-black">
        <div className="d-flex flex-column">
          <Navbar.Brand className="text-white py-3 create-event-title">
            Create events
          </Navbar.Brand>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link className="create-event-text" to="/events">
                Events
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <Link className="create-event-text" to="/events/create-event">
                Create Events
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Navbar.Toggle aria-controls="myNavbar2" className="navbar-toggler">
          <RxHamburgerMenu className="text-white fs-4" />
        </Navbar.Toggle>

        <Navbar.Collapse id="myNavbar2">
          <div className="d-flex ms-auto flex-wrap  justify-content-start">
            <Link to="/events">
              <Button variant="dark rounded-5" style={{ fontSize: "14px" }}>
                Go back to events <IoArrowBackOutline />
              </Button>
            </Link>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default CreateEventHeader;
