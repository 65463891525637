import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import BarChartt from "./BarChartt";
import DoughnutChart from "./DoughnutChart";

function Chart() {
  return (
    <Container fluid className="py-4">
      <Row>
        <Col lg={6} md={12} className="mb-4">
          <Card className="bg-white mx-auto rounded-4 p-4">
            <DoughnutChart />
          </Card>
        </Col>
        <Col lg={6} md={12} className="mb-4">
          <Card className="bg-white rounded-4 p-4">
            <BarChartt />
          </Card>
        </Col>
        <Col lg={6} md={12} className="mb-4">
          <Card className="bg-white rounded-4 p-4">
            <BarChartt />
          </Card>
        </Col>
        <Col lg={6} md={12} className="mb-4">
          <Card className="bg-white rounded-4 p-4">
            <BarChartt />
          </Card>
        </Col>
        <Col lg={6} md={12} className="mb-4">
          <Card className="bg-white rounded-4 p-4">
            <BarChartt />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Chart;
