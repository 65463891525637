import React, { ReactNode, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";

interface OwlCarouselProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

const OwlCarouselComponent: React.FC<OwlCarouselProps> = ({
  children,
}) => {
  const [carouselKey, setCarouselKey] = useState(0);

  useEffect(() => {
    // Incrementing key to remount the OwlCarousel component
    const timer = setTimeout(() => {
      setCarouselKey((prevKey) => prevKey + 1);
    }, 0);

    return () => clearTimeout(timer);
  }, [children]);

  const owlOptions = {
    margin: 20,
    nav: false,
    autoWidth: true,
    center: false,
    dots: false,
  };

  return <OwlCarousel key={carouselKey} {...owlOptions}>{children}</OwlCarousel>;
};

export default OwlCarouselComponent;
