import LoginPage from "../pages/auth/LoginPage";
import { AuthLayout, MainLayout, NotFound } from "../shared";
import {
  CreateEvent,
  Dashboard,
  Events,
  Homepage,
  Users,
  FullDetailsEvent,
} from "../../presentation/pages";
import SignUpRegistration1 from "../pages/auth/SignUpRegistration1";
import { Routes, Route, Navigate } from "react-router-dom";
import EditEvent from "presentation/pages/main/events/editEvent/EditEvent";
import ResetPassword from "presentation/pages/auth/ResetPassword";
import UnauthorizedPage from "presentation/pages/auth/Unauthorized";
import SettingLayout from "presentation/shared/SettingLayout";
import Account from "presentation/pages/main/settings/account/Account";
import RegisterPage from "presentation/pages/auth/RegisterPage";
import SignUpRegistration2 from "presentation/pages/auth/SignUpRegistration2";
import VerifyOtp from "presentation/pages/auth/VerifyOtp";
import RequestOtp from "presentation/pages/auth/RequestOtp";
import ContinueUssdRegisration from "presentation/pages/auth/ContinueUssdRegisration";
import ForgetPassword from "presentation/pages/auth/ForgetPassword";
import VerifyOTPPassword from "presentation/pages/auth/VerifyOTPPassword";
import Regions from "presentation/pages/main/events/regions/Regions";
import Districts from "presentation/pages/main/events/districts/Districts";
import MediaHouse from "presentation/pages/main/events/mediaHouses/MediaHouse";
import Partners from "presentation/pages/main/events/partners/Partners";
import UserRole from "presentation/pages/main/users/userRole/UserRole";
import ViewUsersWithRoles from "presentation/pages/main/users/viewUsersWithRoles/ViewUsersWithRoles";
import MakeEnquiriesPage from "presentation/pages/main/enquiries/make-enquiries/MakeEnquiriesPage";
import ListEnquiriesPage from "presentation/pages/main/enquiries/list-enquires/ListEnquiriesPage";
import ECounselling from "presentation/pages/main/ecounselling/request/ECounselling";
import RequestApproval from "presentation/pages/main/ecounselling/request/RequestApproval";
import MessageSent from "presentation/pages/main/enquiries/make-enquiries/MessageSent";
import ReplySent from "presentation/pages/main/enquiries/list-enquires/ReplySent";
import CounsellingList from "presentation/pages/main/ecounselling/list/CounsellingList";
import Counsellor from "presentation/pages/main/ecounselling/counsellor/Counsellor";
import CounsellorProfile from "presentation/pages/main/ecounselling/counsellor/components/CounsellorProfile";
import CancelCounsellRequest from "presentation/pages/main/ecounselling/request/components/CancelCounsellRequest";
import RescheduleCounsellRequest from "presentation/pages/main/ecounselling/request/components/RescheduleCounsellRequest";
import FeedbackSent from "presentation/pages/main/ecounselling/request/components/FeedbackSent";
import Groups from "presentation/pages/main/ementoring/groups/Groups";
import RequestToJoin from "presentation/pages/main/ementoring/groups/components/RequestToJoin";
import List from "presentation/pages/main/ementoring/list/List";
import ApproveRequestMessage from "presentation/pages/main/ementoring/list/components/approveRequest/ApproveRequestMessage";
import RemoveMemberMessage from "presentation/pages/main/ementoring/groups/components/removeMember/RemoveMemberMessage";
import LeaveGroupMessage from "presentation/pages/main/ementoring/groups/components/leaveGroup/LeaveGroupMessage";
import DeclineRequestMessage from "presentation/pages/main/ementoring/list/components/declineRequest/DeclineRequestMessage";
import CreateEventMessage from "presentation/pages/main/events/createEvent/CreateEventMessage";

const AppRouter = () => {
  return (
    <Routes>
      {/* Home */}
      <Route path="/">
        <Route index element={<Navigate replace to="home" />} />
        <Route path="home" element={<Homepage />} />
        <Route path="unauthorized" element={<UnauthorizedPage />} />
      </Route>

      {/* Main routes */}
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Navigate replace to="dashboard" />} />
        <Route path="dashboard" element={<Dashboard />} />
      </Route>

      {/* users */}
      <Route path="/users" element={<MainLayout />}>
        <Route index element={<Navigate replace to="users" />} />
        <Route path="users" element={<Users />} />
        <Route path="roles" element={<UserRole />} />
        <Route path="view-users-roles/:role" element={<ViewUsersWithRoles />} />
      </Route>

      {/* Events */}
      <Route path="/events">
        <Route index element={<Navigate replace to="events" />} />
        <Route path="publish" element={<CreateEventMessage />} />
        <Route element={<MainLayout />}>
          <Route path="events" element={<Events />} />
          <Route path="create-event" element={<CreateEvent />} />
          <Route path="event-details/:id" element={<FullDetailsEvent />} />
          <Route path="edit-event/:id" element={<EditEvent />} />
          <Route path="regions" element={<Regions />} />
          <Route path="districts" element={<Districts />} />
          <Route path="media-houses" element={<MediaHouse />} />
          <Route path="partners" element={<Partners />} />
        </Route>
      </Route>

      {/* Enquiries */}
      <Route path="/enquiries">
        <Route index element={<Navigate replace to="enquiries" />} />
        <Route path="make" element={<MakeEnquiriesPage />} />
        <Route path="message-sent" element={<MessageSent />} />
        <Route path="reply-sent" element={<ReplySent />} />
        <Route path="list" element={<MainLayout />}>
          <Route index element={<ListEnquiriesPage />} />
        </Route>
      </Route>

      <Route path="/e-counselling">
        <Route index element={<Navigate replace to="request" />} />
        <Route path="approval" element={<RequestApproval />} />
        <Route path="request/cancel" element={<CancelCounsellRequest />} />
        <Route
          path="request/reschedule"
          element={<RescheduleCounsellRequest />}
        />
        <Route path="request/feedback" element={<FeedbackSent />} />
        <Route element={<MainLayout />}>
          <Route path="request" element={<ECounselling />} />
          <Route path="list" element={<CounsellingList />} />
          <Route path="counsellor" element={<Counsellor />} />
          <Route path="view-profile/:userId" element={<CounsellorProfile />} />
        </Route>
      </Route>

      {/* E-Mentoring */}
      <Route path="/e-mentoring">
        <Route index element={<Navigate replace to="groups" />} />
        <Route path="request-to-join" element={<RequestToJoin />} />
        <Route path="approval" element={<ApproveRequestMessage />} />
        <Route path="removal" element={<RemoveMemberMessage />} />
        <Route path="leave-group" element={<LeaveGroupMessage />} />
        <Route path="decline" element={<DeclineRequestMessage />} />
        <Route element={<MainLayout />}>
          <Route path="groups" element={<Groups />} />
          <Route path="list" element={<List />} />
        </Route>
      </Route>

      {/* Auth routes */}
      <Route path="/auth" element={<AuthLayout />}>
        <Route index element={<Navigate replace to="login" />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="forget-password" element={<ForgetPassword />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="signup-registration-1" element={<SignUpRegistration1 />} />
        <Route path="signup-registration-2" element={<SignUpRegistration2 />} />
        <Route path="verify-otp" element={<VerifyOtp />} />
        <Route path="verify-otp-password" element={<VerifyOTPPassword />} />
        <Route path="request-otp" element={<RequestOtp />} />
        <Route
          path="continue-ussd-registration"
          element={<ContinueUssdRegisration />}
        />
      </Route>

      {/* setting routes */}
      <Route path="/settings" element={<SettingLayout />}>
        <Route index element={<Navigate replace to="account" />} />
        <Route path="account" element={<Account />} />
      </Route>

      {/* Page not found route */}
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRouter;
