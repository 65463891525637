import { useVolunteerAddUserMutation } from "services/user-service";
import { VolunteerCreateUser } from "models/request/create-user.request";
import { useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { TbUsersPlus } from "react-icons/tb";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";

const CreateUserModal = () => {
  const navigate = useNavigate();
  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedDisabilityOptions, setSelectedDisabilityOptions] = useState(
    []
  );
  const [springboadEmpty, setSpringboardEmpty] = useState<boolean>(false);
  const [disabilityStatusEmpty, setDisabilityStatusEmpty] =
    useState<boolean>(false);

  const [onboardUser, { isLoading: isLoginLoading }] =
    useVolunteerAddUserMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm<VolunteerCreateUser>({
    mode: "all",
    defaultValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      gender: "",
      whatsappNumber: "",
      age: "",
      springboardCategory: [],
      disabilityStatus: [],
      highestLevelOfEducation: "",
      onboardedBy: userId,
    },
  });

  const options = [
    { value: "Employer", label: "Employer" },
    { value: "Job Seeker(Student)", label: "Job Seeker(Student)" },
    { value: "Job Seeker(Unemployed)", label: "Job Seeker(Unemployed)" },
    { value: "Job Seeker(Employee)", label: "Job Seeker(Employee)" },
    {
      value: "Support Partner(Volunteer)",
      label: "Support Partner(Volunteer)",
    },
    { value: "Support Partner(Vendor)", label: "Support Partner(Vendor)" },
    {
      value: "Support Partner(Facilitator)",
      label: "Support Partner(Facilitator)",
    },
    {
      value: "Stakeholder",
      label: "Stakeholder",
    },
    {
      value: "Association, Group Or Partner",
      label: "Association, Group Or Partner",
    },
  ];

  const disabiltyOptions = [
    { value: "None", label: "None" },
    { value: "Blind", label: "Blind" },
    { value: "Deaf", label: "Deaf" },
    { value: "Physically Challenged", label: "Physically Challenged" },
    { value: "Speech Impaired", label: "Speech Impaired" },
    { value: "Albino", label: "Albino" },
  ];

  const handleCreate = (inputValue) => {
    const newOption = { value: inputValue.toLowerCase(), label: inputValue };
    setSelectedOptions([...selectedOptions, newOption]);
  };

  const handleDisabilty = (inputValue) => {
    const newOption = { value: inputValue.toLowerCase(), label: inputValue };
    setSelectedDisabilityOptions([...selectedDisabilityOptions, newOption]);
  };

  const handleChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
    setSpringboardEmpty(false);
  };
  const handleChangeDisabilty = (selectedValues) => {
    const isNoneSelected = selectedValues.some(
      (option) => option.value === "None"
    );
    if (isNoneSelected) {
      setSelectedDisabilityOptions([{ value: "None", label: "None" }]);
    } else {
      setSelectedDisabilityOptions(selectedValues);
    }
    setDisabilityStatusEmpty(false); // Reset the empty state
  };

  const onSubmit: SubmitHandler<VolunteerCreateUser> = async (
    formData,
    event
  ) => {
    event.preventDefault();

    try {
      if (selectedOptions.length === 0) {
        console.error("Springboard category is required");
        setSpringboardEmpty(true);
      }
      if (selectedDisabilityOptions.length === 0) {
        console.error("Disability Status required");
        setDisabilityStatusEmpty(true);
      }

      const finalResponse = {
        ...formData,
        disabilityStatus: selectedDisabilityOptions.map((value) => value.label),
        springboardCategory: selectedOptions.map((value) => value.label),
      };

      const response = await onboardUser(finalResponse);
      const { code, message, data } = response["data"];
      console.log("API Response:", response);

      if (code === 201) {
        const { id } = data;
        sessionStorage.setItem("registrationId", id);
        toast.success(message);
        setTimeout(() => {
          navigate(`/auth/signup-registration-1`, { state: finalResponse });
        }, 2000);
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error in API call:", error);
      toast(error.message);
    }
  };

  return (
    <>
      <Button
        className="rounded-5 text-black border-0"
        style={{ fontSize: "12px", background: "rgba(25, 22, 29, 0.06)" }}
        onClick={handleShow}
      >
        Create new user <TbUsersPlus />
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Create User</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)} className="g-3 ">
          <Modal.Body className="m-0 p-0">
            <Card className="border-0  rounded-3">
              <Card.Body className=" p-4">
                <Row>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control
                      type="text"
                      placeholder="First name"
                      size="lg"
                      {...register("firstName", {
                        required: {
                          value: true,
                          message: "First name is required",
                        },
                        pattern: {
                          value: /^[A-Za-z0-9\-]+$/,
                          message: "Please enter only alphanumeric",
                        },
                      })}
                    />
                    <p className="text-danger">{errors.firstName?.message}</p>
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control
                      type="text"
                      placeholder="Middle name"
                      size="lg"
                      {...register("middleName", {
                        pattern: {
                          value: /^[A-Za-z0-9\-]+$/,
                          message: "Please enter only alphanumeric",
                        },
                      })}
                    />
                    <p className="text-danger">{errors.middleName?.message}</p>
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control
                      type="text"
                      placeholder="Last name"
                      size="lg"
                      {...register("lastName", {
                        required: {
                          value: true,
                          message: "Lastname is required",
                        },
                        pattern: {
                          value: /^[A-Za-z0-9\-]+$/,
                          message: "Please enter only alphanumeric",
                        },
                      })}
                    />

                    <p className="text-danger">{errors.lastName?.message}</p>
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      size="lg"
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Email is required",
                        },

                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: "Email format is invalid",
                        },
                      })}
                    />
                    <p className="text-danger">{errors.email?.message}</p>
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control
                      type="text"
                      placeholder="Mobile Number"
                      size="lg"
                      {...register("phone", {
                        required: {
                          value: true,
                          message: "Phone number is required",
                        },
                        pattern: {
                          value: /^(233|0)\d{9}$/,
                          message: "Phone number must be a valid number",
                        },
                      })}
                    />
                    <p className="text-danger">{errors.phone?.message}</p>
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control
                      type="text"
                      placeholder="WhatsApp Number"
                      size="lg"
                      {...register("whatsappNumber", {
                        pattern: {
                          value: /^(233|0)\d{9}$/,
                          message: "Invalid WhatsApp number",
                        },
                      })}
                    />
                    <p className="text-danger">
                      {errors.whatsappNumber?.message}
                    </p>
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Select
                      className="text-muted"
                      size="lg"
                      {...register("age", {
                        required: {
                          value: true,
                          message: "Age is required",
                        },
                      })}
                      defaultValue=""
                    >
                      <option value="" disabled>
                        Age
                      </option>
                      <option value="AGE_BELOW_15">Below 15yrs</option>
                      <option value="AGE_15_17">15 – 17 yrs</option>
                      <option value="AGE_18_24">18 – 24 yrs</option>
                      <option value="AGE_25_30">25 – 30 yrs </option>
                      <option value="AGE_31_35">31 – 35 years </option>
                      <option value="AGE_36_PLUS">36+ years </option>
                    </Form.Select>
                    <p className="text-danger">{errors.age?.message}</p>
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Select
                      className="text-muted"
                      size="lg"
                      {...register("gender", {
                        required: {
                          value: true,
                          message: "Gender is required",
                        },
                      })}
                      defaultValue=""
                    >
                      <option value="" disabled>
                        Gender
                      </option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </Form.Select>
                    <p className="text-danger">{errors.gender?.message}</p>
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Select
                      className="text-muted"
                      size="lg"
                      {...register("highestLevelOfEducation", {
                        required: {
                          value: true,
                          message: "Educational level is required",
                        },
                      })}
                      defaultValue=""
                    >
                      <option value="" disabled>
                        Highest Level of Eduction
                      </option>
                      <option value="No Formal Education">
                        No Formal Education
                      </option>
                      <option value="Primary">Primary</option>
                      <option value="Junior High School (JHS)">
                        Junior High School (JHS)
                      </option>
                      <option value="Senior High School (SHS)">
                        Senior High School (SHS)
                      </option>
                      <option value="TVET (Secondary)">TVET (Secondary)</option>
                      <option value="TVET (Tertiary)">TVET (Tertiary)</option>
                      <option value="First Degree / Bachelors">
                        First Degree / Bachelors
                      </option>
                      <option value="Master’s Degree">Master’s Degree</option>
                      <option value="Doctorate Degree">Doctorate Degree</option>
                    </Form.Select>
                    <p className="text-danger">
                      {errors.highestLevelOfEducation?.message}
                    </p>
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Controller
                      name="springboardCategory"
                      control={control}
                      render={({ field }) => (
                        <div>
                          <MultiSelect
                            options={options}
                            className="react-select-container "
                            value={selectedOptions}
                            onChange={handleChange}
                            onCreateOption={handleCreate}
                            labelledBy="Springboard Category"
                            overrideStrings={{
                              selectSomeItems: "Springboard Category",
                            }}
                            hasSelectAll={false}
                          />
                          {springboadEmpty ? (
                            <p className="text-danger">
                              Springboard Category is required
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    />
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Controller
                      name="disabilityStatus"
                      control={control}
                      render={({ field }) => (
                        <div>
                          <MultiSelect
                            options={disabiltyOptions}
                            value={selectedDisabilityOptions}
                            onChange={handleChangeDisabilty}
                            labelledBy="Disability Status"
                            onCreateOption={handleDisabilty}
                            className="react-select-container"
                            overrideStrings={{
                              selectSomeItems: "Disability Status",
                            }}
                            hasSelectAll={false}
                          />
                          {/* Updated condition for error message */}
                          {disabilityStatusEmpty ? (
                            <p className="text-danger">
                              Disability Status is required
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    />
                  </Form.Group>
                </Row>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="dark"
              className="users-text rounded-5"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              type="submit"
              className="users-text rounded-5"
              variant="success"
            >
              Create User
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default CreateUserModal;
