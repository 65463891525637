import TextWithLine from "./components/TextWithLine";
import React, { ChangeEvent, useState } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { GoArrowRight } from "react-icons/go";
import { useCreateUserMutation } from "services/user-service";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { MultiSelect } from "react-multi-select-component";
import { CreateUser } from "@models/request/create-user.request";
import Spinner from "react-bootstrap/Spinner";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import { FiEye, FiEyeOff } from "react-icons/fi";

const RegisterPage = () => {
  const [createUser, { isLoading: isLoginLoading }] = useCreateUserMutation();
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [selectedDisabilityOptions, setSelectedDisabilityOptions] = useState(
    []
  );
  const [springboadEmpty, setSpringboardEmpty] = useState<boolean>(false);
  const [disabilityStatusEmpty, setDisabilityStatusEmpty] =
    useState<boolean>(false);
  const [consent, setConsent] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm<CreateUser>({
    defaultValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      gender: "M",
      confirmPassword: "",
      whatsappNumber: "",
      age: "",
      springboardCategory: [],
      disabilityStatus: [],
      highestLevelOfEducation: "",
    },
  });
  const options = [
    { value: "Employer", label: "Employer" },
    { value: "Job Seeker(Student)", label: "Job Seeker(Student)" },
    { value: "Job Seeker(Unemployed)", label: "Job Seeker(Unemployed)" },
    { value: "Job Seeker(Employee)", label: "Job Seeker(Employee)" },
    {
      value: "Support Partner(Volunteer)",
      label: "Support Partner(Volunteer)",
    },
    { value: "Support Partner(Vendor)", label: "Support Partner(Vendor)" },
    {
      value: "Support Partner(Facilitator)",
      label: "Support Partner(Facilitator)",
    },
    {
      value: "Stakeholder",
      label: "Stakeholder",
    },
    {
      value: "Association, Group Or Partner",
      label: "Association, Group Or Partner",
    },
  ];

  const disabiltyOptions = [
    { value: "None", label: "None" },
    { value: "Blind", label: "Blind" },
    { value: "Deaf", label: "Deaf" },
    { value: "Physically Challenged", label: "Physically Challenged" },
    { value: "Speech Impaired", label: "Speech Impaired" },
    { value: "Albino", label: "Albino" },
  ];

  const handleCreate = (inputValue) => {
    const newOption = { value: inputValue.toLowerCase(), label: inputValue };
    setSelectedOptions([...selectedOptions, newOption]);
  };

  const handleDisabilty = (inputValue) => {
    const newOption = { value: inputValue.toLowerCase(), label: inputValue };
    setSelectedDisabilityOptions([...selectedDisabilityOptions, newOption]);
  };

  const handleChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
    setSpringboardEmpty(false);
  };
  const handleChangeDisabilty = (selectedValues) => {
    const isNoneSelected = selectedValues.some(
      (option) => option.value === "None"
    );
    if (isNoneSelected) {
      setSelectedDisabilityOptions([{ value: "None", label: "None" }]);
    } else {
      setSelectedDisabilityOptions(selectedValues);
    }
    setDisabilityStatusEmpty(false); // Reset the empty state
  };

  const handleConsentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConsent(e.target.checked);
  };

  const onSubmit: SubmitHandler<CreateUser> = async (formData, event) => {
    event.preventDefault();

    try {
      if (selectedOptions.length === 0) {
        console.error("Springboard category is required");
        setSpringboardEmpty(true);
      }
      if (selectedDisabilityOptions.length === 0) {
        console.error("Disability Status required");
        setDisabilityStatusEmpty(true);
      }

      if (consent) {
        const finalResponse = {
          ...formData,
          disabilityStatus: selectedDisabilityOptions.map(
            (value) => value.label
          ),
          springboardCategory: selectedOptions.map((value) => value.label),
        };

        const response = await createUser(finalResponse);
        const { code, message, data } = response["data"];
        console.log("API Response:", response);

        if (code === 201) {
          const { id } = data;
          sessionStorage.setItem("registrationId", id);
          toast.success(message);
          setTimeout(() => {
            navigate(`/auth/signup-registration-1`, { state: finalResponse });
          }, 2000);
        } else {
          toast.error(message || "Something went wrong");
        }
      } else {
        toast.error("Please accept the consent agreement");
        return;
      }
    } catch (error) {
      console.error("Error in API call:", error);
      toast(error.message);
    }
  };

  return (
    <Container className="px-lg-5">
      <div className="mt-3">
        <h2 className="fw-bolder">Sign up</h2>
        <p className="text-muted">
          Kindly enter the details below to successfully sign up
        </p>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* Bio section */}
        <TextWithLine label="Bio" extraClasses="mb-3 mt-5" />
        <Row md={3} className="gx-2 gy-2">
          <Form.Group as={Col} md={4}>
            <Form.Control
              type="text"
              placeholder="First name"
              size="lg"
              {...register("firstName", {
                required: {
                  value: true,
                  message: "First name is required",
                },
                pattern: {
                  value: /^[A-Za-z0-9\-]+$/,
                  message: "Please enter only alphanumeric",
                },
              })}
            />
            <p className="text-danger">{errors.firstName?.message}</p>
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Control
              type="text"
              placeholder="Middle name"
              size="lg"
              {...register("middleName", {
                pattern: {
                  value: /^[A-Za-z0-9\-]+$/,
                  message: "Please enter only alphanumeric",
                },
              })}
            />
            <p className="text-danger">{errors.middleName?.message}</p>
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Control
              type="text"
              placeholder="Last name"
              size="lg"
              {...register("lastName", {
                required: {
                  value: true,
                  message: "Lastname is required",
                },
                pattern: {
                  value: /^[A-Za-z0-9\-]+$/,
                  message: "Please enter only alphanumeric",
                },
              })}
            />

            <p className="text-danger">{errors.lastName?.message}</p>
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Select
              className="text-muted"
              size="lg"
              {...register("gender", {
                required: { value: true, message: "Gender is required" },
              })}
              defaultValue=""
            >
              <option value="" disabled>
                Gender
              </option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </Form.Select>
            <p className="text-danger">{errors.gender?.message}</p>
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Select
              className="text-muted"
              size="lg"
              {...register("age", {
                required: { value: true, message: "Age is required" },
              })}
              defaultValue=""
            >
              <option value="" disabled>
                Age
              </option>
              <option value="AGE_BELOW_15">Below 15yrs</option>
              <option value="AGE_15_17">15 – 17 yrs</option>
              <option value="AGE_18_24">18 – 24 yrs</option>
              <option value="AGE_25_30">25 – 30 yrs </option>
              <option value="AGE_31_35">31 – 35 years </option>
              <option value="AGE_36_PLUS">36+ years </option>
            </Form.Select>
            <p className="text-danger">{errors.age?.message}</p>
          </Form.Group>
        </Row>
        {/* End bio section */}

        {/* Contact and address section */}
        <TextWithLine label="Contact and Address" extraClasses="my-3" />
        <Row md={3} className="gx-2 gy-2">
          <Form.Group as={Col} md={4}>
            <Form.Control
              type="text"
              placeholder="Email"
              size="lg"
              {...register("email", {
                required: { value: true, message: "Email is required" },

                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Email format is invalid",
                },
              })}
            />
            <p className="text-danger">{errors.email?.message}</p>
          </Form.Group>

          <Form.Group as={Col} md={4}>
            <Form.Control
              type="text"
              placeholder="Mobile Number"
              size="lg"
              {...register("phone", {
                required: { value: true, message: "Phone number is required" },
                pattern: {
                  value: /^(233|0)\d{9}$/,
                  message: "Phone number must be a valid number",
                },
              })}
            />
            <p className="text-danger">{errors.phone?.message}</p>
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Control
              type="text"
              placeholder="WhatsApp Number"
              size="lg"
              {...register("whatsappNumber", {
                pattern: {
                  value: /^(233|0)\d{9}$/,
                  message: "Invalid WhatsApp number",
                },
              })}
            />
            <p className="text-danger">{errors.whatsappNumber?.message}</p>
          </Form.Group>
        </Row>
        {/* End contact and address section */}

        {/* Education and other */}
        <TextWithLine label="Education and other" extraClasses="my-3" />
        <Row md={3} className="gx-2 gy-2">
          <Form.Group as={Col} md={4}>
            <Form.Select
              className="text-muted"
              size="lg"
              {...register("highestLevelOfEducation", {
                required: {
                  value: true,
                  message: "Educational level is required",
                },
              })}
              defaultValue=""
            >
              <option value="" disabled>
                Highest Level of Eduction
              </option>
              <option value="No Formal Education">No Formal Education</option>
              <option value="Primary">Primary</option>
              <option value="Junior High School (JHS)">
                Junior High School (JHS)
              </option>
              <option value="Senior High School (SHS)">
                Senior High School (SHS)
              </option>
              <option value="TVET (Secondary)">TVET (Secondary)</option>
              <option value="TVET (Tertiary)">TVET (Tertiary)</option>
              <option value="First Degree / Bachelors">
                First Degree / Bachelors
              </option>
              <option value="Master’s Degree">Master’s Degree</option>
              <option value="Doctorate Degree">Doctorate Degree</option>
            </Form.Select>
            <p className="text-danger">
              {errors.highestLevelOfEducation?.message}
            </p>
          </Form.Group>

          <Form.Group as={Col} md={4}>
            <Controller
              name="springboardCategory"
              control={control}
              render={({ field }) => (
                <div>
                  <MultiSelect
                    options={options}
                    className="react-select-container"
                    value={selectedOptions}
                    onChange={handleChange}
                    onCreateOption={handleCreate}
                    labelledBy="Springboard Category"
                    overrideStrings={{
                      selectSomeItems: "Springboard Category",
                    }}
                    hasSelectAll={false}
                  />
                  {springboadEmpty ? (
                    <p className="text-danger">
                      Springboard Category is required
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              )}
            />
          </Form.Group>

          <Controller
            name="disabilityStatus"
            control={control}
            render={({ field }) => (
              <div>
                <MultiSelect
                  options={disabiltyOptions}
                  value={selectedDisabilityOptions}
                  onChange={handleChangeDisabilty}
                  labelledBy="Disability Status"
                  onCreateOption={handleDisabilty}
                  className="react-select-container"
                  overrideStrings={{ selectSomeItems: "Disability Status" }}
                  hasSelectAll={false}
                />
                {/* Updated condition for error message */}
                {disabilityStatusEmpty ? (
                  <p className="text-danger">Disability Status is required</p>
                ) : (
                  ""
                )}
              </div>
            )}
          />
        </Row>
        {/* End education and other */}

        {/* Credentials */}
        <TextWithLine label="Kindly set your password" extraClasses="my-3" />
        <Row md={3} className="gx-2 gy-2 flex-column flex-sm-row">
          <Form.Group as={Col}>
            <div className="d-flex align-items-center">
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                size="lg"
                {...register("password", {
                  required: "Password is required",
                  pattern: {
                    value:
                      /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[A-Za-z0-9]).{8,}$/,
                    message:
                      "It should have at least 8 characters and special character",
                  },
                })}
              />
              {showPassword ? (
                <FiEyeOff
                  className="eye-icon"
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <FiEye
                  className="eye-icon"
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </div>
            <p className="text-danger">{errors.password?.message}</p>
          </Form.Group>
          <Form.Group as={Col}>
            <div className="d-flex align-items-center">
              <Form.Control
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                size="lg"
                {...register("confirmPassword", {
                  required: "Confirm Password is required",
                  validate: (value) =>
                    value === getValues("password") ||
                    "The passwords do not match",
                })}
              />
              {showConfirmPassword ? (
                <FiEyeOff
                  className="eye-icon"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              ) : (
                <FiEye
                  className="eye-icon"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              )}
            </div>
            <p className="text-danger">{errors.confirmPassword?.message}</p>
          </Form.Group>
        </Row>
        {/* End credentials */}

        {/* Button and agreement */}
        <Row className="mt-4">
          <Col>
            <Form.Check
              type="checkbox"
              checked={consent}
              onChange={handleConsentChange}
              label="I give my consent to participate in the Ghana Grows Programme. 
    I acknowledge that my data will be recorded for the purposes of any interventions
    or opportunities from the programme."
              className={!consent ? "highlight-checkbox" : ""}
            />
          </Col>

          <Row className="mt-5">
            <Col className="text-end">
              <Button
                variant="dark"
                type="submit"
                size="lg"
                className="rounded-5 w-50"
                // disabled={!consent}
              >
                {isLoginLoading ? (
                  <Spinner
                    animation="border"
                    style={{ height: "20px", width: "20px" }}
                  />
                ) : (
                  <>
                    <span>
                      Sign up
                      <GoArrowRight className="mx-3" />
                    </span>
                  </>
                )}
              </Button>
              <p className="text-start mt-3">
                Already have an account?
                <Link to="/auth/login" className="text-decoration-none ms-1">
                  Sign in
                </Link>
              </p>
            </Col>
          </Row>
        </Row>
        {/* End button and agreement */}
      </Form>
    </Container>
  );
};

export default RegisterPage;
