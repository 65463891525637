import { Col, Row } from "react-bootstrap";
import { DateRange } from "react-date-range";

const DateCalender = ({ date, onDateChange, startDayValue, endDayValue }) => {
  return (
    <Row>
      <Col xs={12} className="bg-white mt-5 event-details-calender">
        <p style={{ fontSize: "12px" }}>
          Start date - End date <span className="text-danger">*</span>
        </p>
        <DateRange
          editableDateInputs={true}
          onChange={onDateChange}
          moveRangeOnFirstSelection={false}
          ranges={[
            {
              startDate: startDayValue ? new Date(startDayValue) : null,
              endDate: endDayValue ? new Date(endDayValue) : null,
              key: "selection",
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default DateCalender;
