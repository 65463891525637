import React from "react";
import { Col, Pagination, Row } from "react-bootstrap";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";

const SharedPagination = ({
  currentPage,
  totalPages,
  totalElements,
  pageSize,
  onPageChange,
}) => {
  const visiblePages = 4; // Number of visible pagination items
  const halfVisible = Math.floor(visiblePages / 2);
  let startPage = Math.max(1, currentPage - halfVisible);
  let endPage = Math.min(totalPages, currentPage + halfVisible);

  if (endPage - startPage + 1 < visiblePages) {
    if (currentPage <= halfVisible + 1) {
      endPage = Math.min(totalPages, visiblePages);
    } else {
      startPage = Math.max(1, totalPages - visiblePages + 1);
    }
  }

  const startEntity = currentPage;
  const endEntity = Math.min(currentPage * pageSize, totalElements);

  return (
    <>
      <Row className="mb-3 align-items-center">
        <Col>
          Showing {startEntity} to {endEntity} of {totalElements} entities
        </Col>
        <Col>
          <Pagination className="d-flex my-3 justify-content-end">
            <Pagination.Prev
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              {currentPage === 1 ? (
                <GrLinkPrevious style={{ color: "#86888A" }} />
              ) : (
                <GrLinkPrevious />
              )}
            </Pagination.Prev>
            {startPage > 1 && (
              <>
                <Pagination.Item onClick={() => onPageChange(1)}>
                  1
                </Pagination.Item>
                {startPage > 2 && <Pagination.Ellipsis disabled />}
              </>
            )}
            {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
              <Pagination.Item
                key={startPage + index}
                active={startPage + index === currentPage}
                onClick={() => onPageChange(startPage + index)}
              >
                {startPage + index}
              </Pagination.Item>
            ))}
            {endPage < totalPages && (
              <>
                {endPage < totalPages - 1 && <Pagination.Ellipsis disabled />}
                <Pagination.Item onClick={() => onPageChange(totalPages)}>
                  {totalPages}
                </Pagination.Item>
              </>
            )}
            <Pagination.Next
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              {currentPage === totalPages ? (
                <GrLinkNext style={{ color: "#86888A" }} />
              ) : (
                <GrLinkNext />
              )}
            </Pagination.Next>
          </Pagination>
        </Col>
      </Row>
    </>
  );
};

export default SharedPagination;
