import { availabilityType } from "models/request/counselling-request";
import { useCheckAvailabilityMutation } from "services/counselling-service";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import toast from "react-hot-toast";

const CounsellorAvailability = ({ mentorID, available }) => {
  const [availData, setAvailData] = useState<availabilityType>({
    mentorId: mentorID,
    availability: true,
  });

  const [checkAvailable] = useCheckAvailabilityMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setAvailData((prevState) => ({
      ...prevState,
      availability: e.target.checked,
    }));

    try {
      const response = await checkAvailable(availData);
      const { code, message } = response["data"];
      if (code === 0) {
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };
  return (
    <div>
      <Form>
        <Form.Check
          type="switch"
          id="custom-switch"
          name="availability"
          // label="Availability"
          checked={available || false}
          onChange={handleFormSubmit}
        />
      </Form>
    </div>
  );
};

export default CounsellorAvailability;
