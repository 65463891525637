import { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/logoreal.png";
import MastercardLogo from "../assets/images/mastercardLogo.png"
import { IoCloseOutline } from "react-icons/io5";
import { LoginBg, signUpBg } from "../assets/images";

const AuthLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [background, setBackground] = useState(signUpBg);
  const isLoginPage = useMemo(
    () => location.pathname === "/auth/login",
    [location.pathname]
  );

  const navigateToLogin = () => {
    navigate("/auth/login");
  };

  console.log("isLoginPage: ", isLoginPage);
  console.log("path: ", location.pathname);

  const handleNavigation = () => {
    if (
      location.pathname === "/auth/verify-otp" ||
      location.pathname === "/auth/request-otp" ||
      location.pathname === "/auth/continue-ussd-registration" ||
      location.pathname === "/auth/reset-password" ||
      location.pathname === "/auth/verify-otp-password" ||
      location.pathname === "/auth/forget-password"
    ) {
      navigateToLogin();
    } else {
      window.location.href = "https://springboard.com.gh/";
    }
  };

  useEffect(() => {
    if (isLoginPage) {
      setBackground(LoginBg);
      console.log(LoginBg);
    } else {
      setBackground(signUpBg);
      console.log(signUpBg);
    }
  }, [isLoginPage]);

  const BackgroundImg = {
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  };

  return (
    <Container className="main-bg overflow-hidden min-vh-100" fluid>
      <Row className="p-1 min-vh-100">
        <Col
          md={
            location.pathname === "/auth/verify-otp" ||
            location.pathname === "/auth/request-otp" ||
            location.pathname === "/auth/continue-ussd-registration" ||
            location.pathname === "/auth/reset-password" ||
            location.pathname === "/auth/verify-otp-password" ||
            location.pathname === "/auth/forget-password"
              ? 7
              : isLoginPage
              ? 7
              : 3
          }
          className="bg-white rounded-4 text-white d-none d-md-block "
          style={BackgroundImg}
        >
          <div
            className="d-flex align-items-start flex-column p-0 m-0"
            style={{ minHeight: "100%" }}
          >
            <div className="mb-auto d-flex">
              <img src={logo} alt="" style={{width: "100px"}} />
              <img src={MastercardLogo} alt="" style={{width: "90px"}}/>
            </div>

            <div>
              <h1>Welcome to Ghana Grows!</h1>
              <p>
                Supporting you to pursue decent and fulfilling jobs in the
                Agricultural and ATVET Sectors
              </p>
            </div>
          </div>
        </Col>

        <Col className="rounded-4 border-0 bg-white ">
          <div className="text-end py-2">
            <Button
              variant="light"
              className="rounded-circle py-2"
              onClick={handleNavigation}
            >
              <IoCloseOutline className="fs-6 fw-bold" />
            </Button>
          </div>
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};

export default AuthLayout;
