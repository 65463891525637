import { Navbar, Button, Row, Col, Form } from "react-bootstrap";
import { Hamburger } from "../../../../../assets/index";
import { Link } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";

const EventsHeader = ({ searchQuery, setSearchQuery }) => {
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const roles = sessionStorage.getItem("roles");
  return (
    <div>
      <Navbar expand="lg" className="py-3 p-4 bg-transparent">
        <Navbar.Brand className="fs-4">Events</Navbar.Brand>

        <Navbar.Toggle aria-controls="myNavbar2" className="navbar-toggler">
          <RxHamburgerMenu className="text-black fs-4" />
        </Navbar.Toggle>

        <Navbar.Collapse id="myNavbar2">
          <Row className="ms-auto flex-wrap align-items-center">
            <Col lg={1} className="mx-auto me-3 p-0">
              <img src={Hamburger} height="48px" alt="" />
            </Col>
            <Col lg={6} className="mx-auto m-0 p-0 position-relative">
              <span className="position-absolute ps-2 pt-2 py-auto">
                <IoSearchOutline />
              </span>
              <Form.Control
                type="search"
                className="py-2 border border-1 rounded-5"
                style={{ paddingLeft: "1.8rem" }}
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </Col>

            {roles &&
              (roles.includes("Admin") ||
                roles.includes("Super Admin") ||
                roles.includes("Comms (Communications)")) && (
                <Col lg={4} className="mx-auto m-0 p-0">
                  <Link className="text-white" to="/events/create-event">
                    <Button
                      className="py-2 rounded-5 mx-auto"
                      variant="dark"
                      style={{ fontSize: "14px" }}
                    >
                      Create event +
                    </Button>
                  </Link>
                </Col>
              )}
          </Row>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default EventsHeader;
