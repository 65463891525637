import React, { ChangeEvent, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { roleType } from "models/request/create-user.request";
import { useCreateUserRolesMutation } from "services/auth-service";

const CreateUserRole = () => {
  const [show, setShow] = useState(false);
  const [roleData, setRoleData] = useState<roleType>({
    name: "",
    description: "",
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setRoleData((prevRegionData) => ({
      ...prevRegionData,
      [name]: value,
    }));
  };

  const [createRoleMutation] = useCreateUserRolesMutation();

  const handleCreateRole = async (e) => {
    e.preventDefault();
    try {
      const response = await createRoleMutation(roleData);
      const { code, message } = response["data"];
      if (code === 200) {
        toast.success(message);
      } else {
        toast.error(message || "Something went wrong");
      }
      handleClose();
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating region:", error);
    }
  };

  return (
    <>
      <Button
        className="py-2 mx-1 rounded-5"
        variant="dark"
        style={{ fontSize: "13px" }}
        onClick={handleShow}
      >
        Create Role +
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Create a role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "14px" }}>Name</Form.Label>
              <Form.Control
                className="p-2"
                type="text"
                placeholder="Enter User role name"
                autoFocus
                name="name"
                onChange={handleFormChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "14px" }}>Description</Form.Label>
              <Form.Control
                className="p-2"
                as="textarea"
                name="description"
                placeholder="Enter User role description"
                onChange={handleFormChange}
                rows={3}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded-5"
            variant="secondary"
            onClick={handleClose}
            style={{ fontSize: "14px" }}
          >
            Close
          </Button>
          <Button
            style={{ fontSize: "14px" }}
            className="rounded-5"
            variant="primary"
            onClick={handleCreateRole} // Call handleCreateRegion on button click
          >
            Create Role
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateUserRole;
