import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { FaArrowRightLong } from "react-icons/fa6";
import Background from "../../assets/images/bg_home.png";
import { Link } from "react-router-dom";

function Homepage() {
  return (
    <div>
      <div
        className="bgg"
        style={{
          background: `url(${Background})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <Container className="vh-100 d-flex align-items-center justify-content-center">
          <Row className="justify-content-center">
            <Col md={12} xs={12}>
              <div className="py-13 pt-lg-20 pb-lg-20 text-center text-light">
                <h1 className="display-3 fw-medium text-white mb-2">
                  Ghana Grows Dashboard.
                </h1>
                <Link to="/auth/login">
                  <Button
                    variant="primary"
                    size="lg"
                    className="mt-5 rounded-5 bg-white text-black w-50 border-0 fs-6"
                  >
                    Let's get started <FaArrowRightLong />
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Homepage;
