import { Form, Button, Container } from "react-bootstrap";
import { GoArrowRight } from "react-icons/go";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { ChangeEvent, useState } from "react";
import { OtpUserRequest } from "models/request/login-user.request";
import { useVerifyOTPMutation } from "services/user-service";

const VerifyOTPPassword: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState<OtpUserRequest>({
    phone: location.state ? location.state.phone : "",
    otp: "",
  });
  const [isOTPLoading, setIsOTPLoading] = useState(false);
  const [verifyOTP] = useVerifyOTPMutation();

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsOTPLoading(true);

    try {
      const response = await verifyOTP(formData);
      const { message, data } = response["data"];
      if (data === true) {
        toast.success(message);
        navigate("/auth/reset-password", {
          state: { phone: formData.phone },
        });
      } else if (data === false) {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    } finally {
      setIsOTPLoading(false);
    }
  };
  return (
    <Container
      className="d-flex align-items-center justify-content-center "
      style={{ height: "90%" }}
    >
      <div className="w-100 px-lg-5">
        <div className="mt-3 ">
          <h2 className="fw-bolder mb-5">
            Verify One-Time Password
          </h2>
        </div>

        <Form onSubmit={handleFormSubmit}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control
              type="text"
              placeholder="Enter OTP"
              size="lg"
              name="otp"
              onChange={handleFormChanged}
              required
            />
          </Form.Group>

          <div className="mt-3">
            <Button variant="dark" type="submit" className="rounded-5 w-100">
              {isOTPLoading ? "Verifying..." : "Verify OTP"}
              <GoArrowRight className="mx-3" />
            </Button>
          </div>
        </Form>
      </div>
    </Container>
  );
};

export default VerifyOTPPassword;
