import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDeleteEventByIdMutation } from "services/event-service";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const DeleteEvent = ({ deleteId }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [deleteEventByIdMutation] = useDeleteEventByIdMutation();

  const handleDelete = async () => {
    try {
      // Call the mutation hook to delete the event by its ID
      const response = await deleteEventByIdMutation(deleteId);
      handleClose();
      const { code, message } = response["data"];
      if (code === "00") {
        toast.success("Event deleted successfully");
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error deleting event:", error);
      toast.error(error.message);
    }
  };

  return (
    <>
      <Dropdown.Item onClick={handleShow} style={{ fontSize: "14px" }}>
        Delete Event
      </Dropdown.Item>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "16px" }}>Delete event</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "14px" }}>
          Are you sure you want to delete this event?
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button
            style={{ fontSize: "14px" }}
            variant="danger"
            className="rounded-5"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteEvent;
