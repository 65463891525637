import { useRescheduleCounsellMutation } from "services/counselling-service";
import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";

const RescheduleCounsellRequest = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const counselRescheduleData = location.state?.rescheduleData;

  const [rescheduleCounsell] = useRescheduleCounsellMutation();

  const handleCancel = async () => {
    try {
      // Call the mutation hook to delete the event by its ID
      const response = await rescheduleCounsell(counselRescheduleData);
      const { code, message } = response["data"];
      if (code === 0) {
        toast.success(message);
        navigate("/e-counselling/request");
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error deleting event:", error);
      toast.error(error.message);
    }
  };
  return (
    <div>
      <div className="bg-enquiries">
        <Container className="vh-100 d-flex align-items-center justify-content-center">
          <Row className="justify-content-center">
            <Col md={12} xs={12}>
              <div className="py-13 pt-lg-20 pb-lg-20 text-center">
                <h1 className="display-3 fw-medium text-white mb-5">
                  Reschedule counselling session
                </h1>
                <div
                  className="modal show rounded-1"
                  style={{ display: "block", position: "initial" }}
                >
                  <Col md={5} className="mx-auto">
                    <Card className="text-center">
                      <Card.Body>
                        <Card.Text className="my-3">
                          You are about to reschedule your counselling session.
                          Are you sure you want to reschedule your session?
                        </Card.Text>

                        <Row>
                          <Col className="text-center my-3">
                            <Button
                              variant="light"
                              className="rounded-5 me-2"
                              onClick={() => navigate(-1)}
                            >
                              Go back <RiArrowLeftLine />
                            </Button>
                            <Button
                              variant="dark"
                              type="submit"
                              className="rounded-5"
                              onClick={handleCancel}
                            >
                              Yes, reschedule session
                              <RiArrowRightLine />
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default RescheduleCounsellRequest;
