import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";

function Stats() {
  return (
    <div>
      <Container className="content-space-1">
        <Row className="py-2 justify-content-between align-items-md-center">
          <Col lg={5} className="m-2 rounded-4 bg-white py-auto">
            <div className="ps-lg-1">
              <Row className="p-3">
                <Col sm className="p-1">
                  <h3 className="fs-5 text-black fw-bold">1,512,951</h3>
                  <p className="mb-0" style={{ fontSize: "14px" }}>
                    Reached
                  </p>
                </Col>

                <Col sm className="p-1">
                  <h3 className="fs-5 text-black fw-bold">700,125</h3>
                  <p className="mb-0" style={{ fontSize: "14px" }}>
                    Onboarding
                  </p>
                </Col>

                <Col sm className="p-1">
                  <h3 className="fs-5 text-black fw-bold">555</h3>
                  <p className="mb-0" style={{ fontSize: "14px" }}>
                    Employment Services
                  </p>
                </Col>

                <Col sm className="p-1">
                  <h3 className="fs-5 text-black fw-bold">700,125</h3>
                  <p className="mb-0" style={{ fontSize: "14px" }}>
                    Youth In Work
                  </p>
                </Col>
              </Row>
            </div>
          </Col>

          <Col lg={5} className="m-2 rounded-4 py-auto">
            <div className="ps-lg-1">
              <Row className="p-3">
                <Col sm className="p-1 d-flex flex-column justify-content-between">
                  <h6 className="text-black fw-bold">
                    Name of Event or Intervention
                  </h6>
                  <Form.Select
                    aria-label="Default select example"
                    style={{ fontSize: "14px" }}
                  >
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </Col>

                <Col sm className="p-1 d-flex flex-column justify-content-between">
                  <h6 className="text-black fw-bold">Name of Partner</h6>
                  <Form.Select
                    aria-label="Default select example"
                    style={{ fontSize: "14px" }}
                  >
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </Col>

                <Col sm className="p-1 d-flex flex-column justify-content-between">
                  <h6 className="text-black fw-bold">Month</h6>
                  <Form.Select
                    aria-label="Default select example"
                    style={{ fontSize: "14px" }}
                  >
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Stats;
