import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageLoader from "presentation/components/PageLoader";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import UsersRoleTable from "../data/UsersRoleTable";
import { useUserRolesQuery } from "services/auth-service";
import CreateUserRole from "./CreateUserRole";
import AssignRole from "./AssignRole";

const UserRoleDataTable = ({ searchQuery }) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [pageSize] = useState(10);

  const { data: response, isLoading } = useUserRolesQuery({
    page: currentPageIndex,
    size: pageSize,
  });

  // Filter the response data based on the search query
  const filteredData =
    response?.data.filter((role) =>
      Object.values(role).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      )
    ) || [];

  return (
    <Container>
      <Row>
        <Col className="d-flex flex-wrap justify-content-end align-items-center mb-4">
          <CreateUserRole />
          <AssignRole />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="w-100 h-auto">
            {isLoading ? (
              <PageLoader />
            ) : (
              <>
                {filteredData.length === 0 ? (
                  <h3 className="text-black">No roles available</h3>
                ) : (
                  <DataGrid
                    rows={filteredData || []}
                    columns={UsersRoleTable}
                    checkboxSelection
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: false,
                      },
                    }}
                    sx={{
                      "& .header--bg": {
                        backgroundColor: "#EBF1FC",
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "#EBF1FC",
                        fontSize: "12px",
                      },
                      "& .Mui-checked .MuiSvgIcon-root": {
                        fill: "black",
                        background: "white",
                      },
                      "& .MuiSvgIcon-root": {
                        width: "18px",
                        height: "18px",
                        borderRadius: "4px",
                        color: "#EBEBEB",
                      },
                      "&.MuiDataGrid-root": {
                        border: "none",
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "Inter",
                        backgroundColor: "white",
                      },
                      "& .MuiDataGrid-footerContainer": {
                        display: "none",
                      },
                    }}
                  />
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default UserRoleDataTable;
