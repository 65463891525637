import { GridColDef } from "@mui/x-data-grid";
import { Badge } from "react-bootstrap";
import AssignCounsellor from "./AssignCounsellor";
import { TbUsers } from "react-icons/tb";
import { VscBook } from "react-icons/vsc";
import { PiSuitcaseSimple } from "react-icons/pi";
import { Image, Row, Button } from "react-bootstrap";

const UnassignedTableElements: GridColDef[] = [
  {
    field: "id",
    headerName: "Id",
    width: 70,
    headerClassName: "header--bg",
  },
  {
    field: "fullName",
    headerName: "Requested By",
    headerClassName: "header--bg",
    width: 200,
    renderCell: (params: any) => (
      <>
        <Image
          src={`https://ui-avatars.com/api/?name=${params?.row?.requestedBy[0]?.firstName}+${params?.row?.requestedBy[0].lastName}&background=random&size=32`}
          alt="Avatar"
          roundedCircle
          className="me-2"
        />
        {params?.row?.requestedBy[0]?.firstName}{" "}
        {params?.row?.requestedBy[0]?.lastName}
      </>
    ),
  },
  {
    field: "requestType",
    headerName: "Request Type",
    width: 140,
    headerClassName: "header--bg",
    renderCell: (params: any) => {
      let icon = null;
      switch (params?.row?.requestType) {
        case "RELATIONSHIP":
          icon = <TbUsers />;
          break;
        case "EDUCATION":
          icon = <VscBook />;
          break;
        case "CAREER":
          icon = <PiSuitcaseSimple />;
          break;
        default:
          icon = null;
          break;
      }
      return (
        <div>
          <Badge className="bg-pill fw-medium" pill text="dark">
            {icon} {params?.row?.requestType}
          </Badge>
        </div>
      );
    },
  },
  {
    field: "preferredStartAndEnd",
    headerName: "Preferred Duration",
    width: 280,
    headerClassName: "header--bg",
    renderCell: (params: any) => (
      <div>
        <Badge className="bg-pill fw-normal" pill text="dark">
          Start Date:{" "}
          {params?.row?.preferredStartAt
            ? new Date(params?.row?.preferredStartAt).toLocaleDateString()
            : ""}{" "}
          -{" "}
          {params?.row?.preferredStartAt
            ? new Date(params?.row?.preferredStartAt).toLocaleTimeString()
            : ""}
        </Badge>
        <br />
        <Badge className="bg-pill fw-normal" pill text="dark">
          End Date:{" "}
          {params?.row?.preferredEndAt
            ? new Date(params?.row?.preferredEndAt).toLocaleDateString()
            : ""}{" "}
          -{" "}
          {params?.row?.preferredEndAt
            ? new Date(params?.row?.preferredEndAt).toLocaleTimeString()
            : ""}
        </Badge>
      </div>
    ),
  },

  {
    field: "referenceCode",
    headerName: "Reference Code",
    headerClassName: "header--bg",
    width: 140,
  },

  {
    field: "createdOn",
    headerName: "Date Created",
    headerClassName: "header--bg",

    valueFormatter: (params: any) =>
      new Date(params.value).toLocaleDateString(),
  },

  {
    field: "actions",
    headerName: "Actions",
    headerClassName: "header--bg",
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      console.log("params", params);
      return (
        <div>
          <AssignCounsellor requestId={params.row.id} />
        </div>
      );
    },
  },
];

export default UnassignedTableElements;
