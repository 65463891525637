import React from "react";
import { Button, Container, Row, Col, Modal, Card } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { useCreateCounSellingMutation } from "services/counselling-service";
import toast from "react-hot-toast";

const RequestApproval = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const counselData = location.state?.counselData;
  const [createCounsel] = useCreateCounSellingMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await createCounsel(counselData);
      const { code, message } = response["data"];
      if (code === 0) {
        toast.success(message);
        navigate("/e-counselling/request");
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };
  return (
    <div>
      <div className="bg-enquiries">
        <Container className="vh-100 d-flex align-items-center justify-content-center">
          <Row className="justify-content-center">
            <Col md={12} xs={12}>
              <div className="py-13 pt-lg-20 pb-lg-20 text-center">
                <h1 className="display-3 fw-medium text-white mb-5">
                  Request for a counsellor
                </h1>
                <div
                  className="modal show rounded-1"
                  style={{ display: "block", position: "initial" }}
                >
                  <Col md={5} className="mx-auto">
                    <Card className="text-center">
                      <Card.Body>
                        <Card.Text className="my-3">
                          You are requesting for counsel on your{" "}
                          {counselData?.requestType}. Click ‘confirm’ to
                          proceed, we will assign you to a counsellor shortly.
                        </Card.Text>

                        <Row>
                          <Col className="text-center my-3">
                            <Button
                              variant="light"
                              className="rounded-5 me-2"
                              onClick={() => navigate(-1)}
                            >
                              Go back <RiArrowLeftLine />
                            </Button>
                            <Button
                              variant="dark"
                              type="submit"
                              className="rounded-5"
                              onClick={handleFormSubmit}
                            >
                              Confirm counsel request
                              <RiArrowRightLine />
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default RequestApproval;
